import React, { useMemo } from 'react';
import Dialog from '../../components/Dialog';
import Controller from '../../mixins/Controller';
import ActionIcon from '../../components/ActionIcon';
import Grid from '../../components/Grid';
import DropZone from '../../components/DropZone';
import Button from '../../components/Button';
import notifications from '../../services/Notification';
import store from 'store';
import DateTimeFormatter from '../../lib/DateTimeFormatter';

const ManualChartsTable = (props) => {
  const loginDetail = store.get('InteServLoginInfo');
  const { userId } = loginDetail
  const controller = props.controller;
  const data = controller.state.nonAPTCharts;
  const link = controller.state.filelink;
  const EditableColumns = useMemo(() => [
    {
      Header: '',
      accessor: 'action',
      Cell: (cellInfo) => {
        return cellInfo.row.original.addedby === userId ? <ActionIcon icon='fa-trash' className='remove-handle' action={() => { controller.deleteChart(cellInfo.row.original.id) }} /> : <></>
      }
    },
    {
      Header: 'Chart Name',
      accessor: 'name',
      Cell: (cellInfo) => {
        return <div><a href={`${link}/${cellInfo.row.original.id}`}>{cellInfo.cell.value}</a></div>
      }
    },
    {
      Header: 'Uploaded By',
      accessor: 'desc',
      Cell: (cellInfo) => {
        return <div>{cellInfo.cell.value}</div>

      }
    },
    {
      Header: 'Uploaded Date',
      accessor: 'dateadded',
      Cell: (cellInfo) => {
        return <div>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</div>
          
      }
    }, 
  ], [])

  return (<div style={{ width: "100%" }}>

    <Grid id='results-table' columns={EditableColumns} data={data} canAddNewRow={false} showPagination={true} noDataText='No Manual Charts Added' />

  </div>);
}
class ManualChartUpload extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
  }

  componentDidMount = () => {
    this.initializeMixin();
  }

  handleClose = () => {
    const parentcontroller = this.props.parentcontroller;
    parentcontroller.updateAssets();
    return true;
  }

  render = () => {
    const width = 650;
    const height = 320;


    const controller = this.props.controller;
    const asset = this.props.controller.state.selectedAsset;
    const parentcontroller = this.props.parentcontroller;
    const handleUploadDiagram = (res) => {
      const selectedAsset = asset;
      const chartFilenames = controller.state.nonAPTCharts.map(x => x.name.toLowerCase());
      const assetNumber = selectedAsset.primarySerialNumber.toLowerCase();
      var fileName = res.file.name;
      const fileType = fileName.split('.')[1] || '';
      
      if (fileType.toLowerCase() != 'pdf') {
        parentcontroller.updateAssets();
        notifications.action('error').post({ msg: 'Invalid file type. .' + fileType + ' is an unrecognized file type to upload', title: 'File Type Error' });
        return;
      }
      if (!fileName.toLowerCase().includes(assetNumber)) {
        controller.getChartsList(selectedAsset);
        notifications.action('error').post({ msg: 'Invalid file name.', title: 'File Name Error' });
        return;
      }      
      if (chartFilenames.length > 0 && chartFilenames.includes(fileName.toLowerCase())) {
        controller.getChartsList(selectedAsset);
        notifications.action('error').post({ msg: 'File with same name already exists for selected asset', title: 'File Exists Error' });
        return;
      }
      const filenamewithoutasset = fileName.toLowerCase().replace(assetNumber.toLowerCase(),'');      
      const juliandate = filenamewithoutasset.split('_')[1] || '';
      if(!juliandate.trim())
      {
        controller.getChartsList(selectedAsset);
        notifications.action('error').post({ msg: 'File contains invalid julian date', title: 'File Name Error' });
        return;
      }
      if (chartFilenames.filter(x => x.toLowerCase().includes(juliandate.toLowerCase())).length > 0) {
        controller.getChartsList(selectedAsset);
        notifications.action('error').post({ msg: 'File with same julian date already exists for selected asset', title: 'File Exists Error' });
        return;
      }
      controller.saveNonAPTCharts(res, selectedAsset);
      parentcontroller.updateAssets();
    }
    return (
      <Dialog {...this.props} header={this.props.header + ' for ' + asset.primarySerialNumber} width={width + 100} height={height + 305} id='asset-chart-dlg' onClose={this.handleClose}>
        <Dialog.Body>
          <ManualChartsTable controller={controller} />
        </Dialog.Body>
        <Dialog.Buttons>
          <>
            <DropZone onDrop={handleUploadDiagram} accept={".pdf"} multiple={false} width={0} height={0} style={{ borderStyle: 'none', padding: 'unset', display: "inline" }}>
              <Button icon='fa-file-upload' variant='success' size='sm' className='m-1' onClick={() => { }}>Upload Chart (Non APT)</Button>
            </DropZone>
          </>
          <Dialog.Button variant='danger' size='sm' className='btn' icon='fa-times' result={{ Cancel: true }}>Close</Dialog.Button>
        </Dialog.Buttons>
      </Dialog>
    );
  }
}

export default ManualChartUpload;
