import React, { useMemo, useEffect } from 'react';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Jumbotron from '../../components/Jumbotron';
import Controller from '../../mixins/Controller';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import AssemblyWorkOrderController from '../controllers/AssemblyWorkOrderController';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import Table from '../../components/Table';
import Grid from '../../components/Grid';
import TopPagination from '../components/TopPagination';
import Search from '../components/Search';
import Searchers from '../utility/Searchers';
import AWOQRSticker from '../components/AWOQRSticker';
import ReactDOM from 'react-dom';

const SearchHeader = (props) => {

  const handleKeyboardSearch = (evt) => {
    if (evt.which !== 13) {
      return;
    }
    props.controller.searchAWOs();
  }

  const handleMouseSearch = () => {
    props.controller.searchAWOs();
  }

  const clearSearch = () => {
    props.controller.clearSearchText();
  }
  const selectNone = (evt) => {
    evt.preventDefault();
    props.controller.selectNone();
  }

  const selectAll = (evt) => {
    evt.preventDefault();
    props.controller.selectAll();
  }
  const binder = props.binder;
  const controller = props.controller;

  return (
    <div {...props} id='search-criteria'>
      <div className='row'>
        <div className='search-container col-md-6' onKeyDown={handleKeyboardSearch}>
          {/* <Form.AutoComplete bind={binder.bind('assemblyWorkOrderSearch')} label='Assembly work Order' prependLabelIcon='fa-filter' placeholder='Select a Assembly Work Order to filter your results' search={Searchers.assemblyWorkOrderSearch()} /> */}
          {/* <Form.Input label='Assembly work Order' bind={binder.bind('assemblyWorkOrderSearch')} prependLabelIcon='fa-filter' placeholder='Enter text search' /> */}
          <Search className='mb-2' id='searchText' inputClassName='search-input text-uppercase' prependLabelIcon='fa-filter' label='Search Text' placeholder='Enter text search' autoFocus='true' bind={binder.bind('awoNumber')} clear={clearSearch} search={handleMouseSearch}></Search>
        </div>
        <div id='customer-search' className='col-md-6'>
          <Form.AutoComplete bind={binder.bind('customer')} label='AWO Customer' prependLabelIcon='fa-filter' placeholder='Select an AWO Customer' search={Searchers.awocustomerSearch()} />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-5'>
          <div className='col-md-12 mb-2 top-margin'>

            <div className='d-flex justify-content-start'>
              <Button icon='fa-square' variant='success' size='sm' className='mr-1' onClick={selectAll}>Select All</Button>
              <Button icon='far fa-square' variant='danger' size='sm' className='mr-1' onClick={selectNone}>Select None</Button>
            </div>

          </div>
        </div>
        <div className='col-md-4'>
          <Form.Label name='AWO Status' />
          <Form.RadioInput bind={binder.bind('searchActive')} label='Active' />
          <Form.RadioInput bind={binder.bind('searchCompleted')} label='Completed' />
        </div>

      </div>
    </div>
  );
}


const Results = (props) => {

  let history = useHistory();

  const getResultData = () => {
    let showSAPStatus = SAPService.isSAPFacility();
    const state = props.controller.state;
    let searchResults = state.searchResults || [];
    searchResults.map((awo) => {
      awo.status = getStatusElement(showSAPStatus, awo);
      return awo;
    });
    return searchResults;
  }

  const getStatusElement = (showSAPStatus, awo) => {
    //return showSAPStatus ? getSAPStatusElement(job) : getNonSAPStatusElement(job);
    return true;
  }

  const getNonSAPStatusElement = (awo) => {
    let statusValue = 0;

    if (awo.assetCount === 0) {
    }
    else if (awo.isComplete) {
      statusValue += 90;
    }
    else if (awo.canComplete) {
      statusValue += 50;
    }
    else if (awo.isInspecting) {
      statusValue += 40;
    }
    else if (awo.isCreated) {
      statusValue += 10;
    }
    else {
      statusValue += 100;
    }

    // set sorting value
    //job.status = statusValue;
    return statusValue;
  }

  const handleRowClick = (awo) => {
    if(awo.length==1)
    {
      props.controller.loadAWODetails(awo[0]);
    }
    else{
      props.controller.loadAWODetails(null);
    }
    
    props.controller.setSelected(awo);
  }

  const handleNotes = (id) => {
    return true;
  }

  const showSAPStatus = SAPService.isSAPFacility();
  const data = getResultData();
  const handlePageChange = () => {
    //TopPagination.Toggle();
  }

  useEffect(() => {
    handlePageChange();
  }, [data]);

  const getRowProps = (row) => {
    const awo = row.original;
    const rowProps = { id: awo.id };
    // if (awo.sapStatus === 'QUOR') {
    //   rowProps.style = { backgroundColor: '#FFA07A' };
    // }
    return rowProps;
  }
  const getCellProps=(cell)=>{
    let style = null;
    if (cell && (['shippedCount','configCount','assetCount','noteCount','abc'].includes(cell.column.id))) {     
       style = {
          width: "10px" 
      };
    }
    if (cell && (['padName','wellName','functionalLocation','serviceLine','wellColorSystem'].includes(cell.column.id))) {     
      style = {
         width: "100px" 
     };
   }   
 if (cell && (['equipmentRequiredDate','awoCreationDate','customerName','awoNumber'].includes(cell.column.id))) {     
  style = {
     width: "100px" 
 }; 
}
if (cell && (['awoNumber'].includes(cell.column.id))) {     
  style = {
     width: "120px" 
 }; 
}
      return style;
}
  const count = props.controller.state.searchResults.length;
  const redirectToAssembly = (awoId) => {
    const assemblyredirect = () => { history.push('assembly') };
    props.controller.redirectToAssembly(awoId, assemblyredirect);
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'abc',
        Cell: cellInfo => {
          let element = null;
          let statusValue = 0;

          if (SAPService.isSAPFacility() && 1!==1) {
            const warning = null;

            if (warning) {
              statusValue++;
            }

            if ((cellInfo.row.original.sapStatus == 'QUNR' || cellInfo.row.original.sapStatus == 'QUNC') && cellInfo.row.original.assetCount === 0) {
              // SAP && non-SAP

              element = <span className='job-no-assets status-column' title='There are no assets on this awo - can be locked'><i className='far fa-circle' />{warning}</span>;
            }
            else if (cellInfo.row.original.isClosed) {
              // SAP && non-SAP
              statusValue += 90;
              element = <span className='job-closed status-column' title='AWO is closed – no further changes can be made'><i className='fa fa-ban' />{warning}</span>;
            }
            else if (cellInfo.row.original.sapStatus == 'QUOR' && !cellInfo.row.original.isQuoteSent && !cellInfo.row.original.isInvoiced && !cellInfo.row.original.canInvoice && !cellInfo.row.original.isCompleting && !cellInfo.row.original.canComplete) {
              statusValue += 35;
              element = <span className='job-setting-up status-column' title='Quote Override'><i className='fa fa-edit' />{warning}</span>;
            }
            else if (cellInfo.row.original.isQuoteSent) {
              statusValue += 38;
              element = <span className='job-setting-up status-column' title='Quote sent to customer for approval'><i className='fa fa-money-bill-alt' />{warning}</span>;
            }
            else if (cellInfo.row.original.isInvoiced) {
              // SAP only
              statusValue += 80;
              element = <span className='job-invoiced status-column' title='Waiting for accounting to complete invoicing'><i className='far fa-clock' />{warning}</span>;
            }
            else if (cellInfo.row.original.canInvoice) {
              // SAP only
              statusValue += 70;
              element = <span className='job-invoicable status-column' title='This job can be sent to accounting for invoicing'><i className='fa fa-dollar-sign' />{warning}</span>;
            }
            else if (cellInfo.row.original.isCompleting) {
              // SAP only
              statusValue += 60;
              element = <span className='job-completing status-column' title='Waiting for AWO to be completed in SAP'><i className='far fa-clock' />{warning}</span>;
            }
            else if (cellInfo.row.original.canComplete) {
              // SAP && non-SAP
              statusValue += 50;
              var title = 'Click Complete AWO to signal that all inspection activities are complete and to remove this entry from the list';
              if (cellInfo.row.original.useSAP) {
                title = 'Click Complete AWO to signal SAP that all inspection activities are complete';
              }
              element = <span className='job-completion-candidate status-column' title={title}><i className='fa fa-check' />{warning}</span>;
            }
            else if (cellInfo.row.original.isWaitingToRetry) {
              statusValue += 45;
              element = <span className='job-retry-status-update status-column' title='AWO is attempting to retry updating Status in SAP'><i className='far fa-clock' />{warning}</span>;
            }
            else if (cellInfo.row.original.isInspecting) {
              // SAP && non-SAP
              statusValue += 40;
              element = <span className='job-active status-column' title='AWO is active, assets can still be received, and asset inspections can still take place'><i className='fa fa-cogs' />{warning}</span>;
            }
            else if (cellInfo.row.original.canLockForInspection) {
              // SAP && non-SAP
              statusValue += 30;
              element = <span className='job-lock-for-inspection-candidate status-column' title='Click Lock Assets to allow Technicians to being inspecting the assets on this AWO'><i className='fas fa-lock-open' />{warning}</span>;
            }
            else if (cellInfo.row.original.isWaiting) {
              // SAP && non-SAP
              statusValue += 20;
              element = cellInfo.row.original.awaitingLockForInspection
                ? <span className='job-creating-service-orders status-column' title='Waiting for Lock Assets request to complete'><i className='far fa-clock' />{warning}</span>
                : <span className='job-creating-service-orders status-column' title='Waiting for SAP request to complete'><i className='far fa-clock' />{warning}</span>;
            }
            else if (cellInfo.row.original.isCreated) {
              statusValue += 10;
              element = <span className='job-setting-up status-column' title='Assets can still be assigned to this AWO from the Receiving page'><i className='fa fa-download' />{warning}</span>;
            }
            else {
              statusValue += 100;
              element = <span></span>;
            }

            // set sorting value
            cellInfo.row.original.status = statusValue;
          }

          else {           
            if (cellInfo.row.original.assetCount === 0) {
              // SAP && non-SAP
              element = <span className='job-no-assets status-column' title='There are no assets on this AWO'><i className='far fa-circle' /></span>;
            }
            else if (cellInfo.row.original.isComplete) {
              statusValue += 90;
              element = <span className='job-closed status-column' title='AWO is complete – no further changes can be made'><i className='fa fa-ban' /></span>;
            }
            else if (cellInfo.row.original.canComplete) {
              statusValue += 50;
              element = <span className='job-completion-candidate status-column' title="Click 'Complete AWO' to remove this entry from the list"><i className='fa fa-check' /></span>;
            }
            else if (cellInfo.row.original.assetsWithassemblyLevelsCount > 0) {
              statusValue += 40;
              element = <span className='job-active status-column' title='There are still assets that need to be shipped – unable to complete at this time'><i className='fa fa-cogs' /></span>;
            }
            else if (cellInfo.row.original.isCreated) {
              statusValue += 10;
              element = <span className='job-setting-up status-column' title='Assets can still be assigned to this AWO from the Receiving page'><i className='fa fa-download' /></span>;
            }
            else {
              if (cellInfo.row.original.assetCount > 0 && cellInfo.row.original.assetsWithassemblyLevelsCount === 0) {
                statusValue += 40;
              element = <span className='job-active status-column' title='There are still assets that need to be shipped – unable to complete at this time'><i className='fa fa-cogs' /></span>;
              }
              else{
                statusValue += 100;
                element = <span></span>;
              }
             
            }

            // set sorting value
            cellInfo.row.original.status = statusValue;
          }
          return element;
        }
      },
      {
        Header: 'Notes',
        accessor: 'noteCount',
        Cell: cellInfo => {
          return <div><Badge variant='warning' className='note-badge' onClick={() => handleNotes(cellInfo.row.original.id)}>{cellInfo.cell.value}</Badge></div>
        }
      },
      {
        Header: 'Functional Location',
        accessor: 'functionalLocation',
        Cell: (cellInfo) => {
          return <div>{cellInfo.cell.value}</div>

        }
      },
      {
        Header: 'AWO Number',
        accessor: 'awoNumber',
        Cell: cellInfo => {
          const isActive = props.controller.state.searchActive;
          return <> {<Form.Link style={{width:"120px" , wordWrap:"break-word"}} onClick={() => {  redirectToAssembly(cellInfo.row.original.id) }} value={cellInfo.cell.value} /> } </>
        }
      },
      {
        Header: 'AWO Creation Date',
        accessor: 'awoCreationDate',
        Cell: cellInfo => <div>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</div>
      },
      {
        Header: 'Equipment Required Date',
        accessor: 'equipmentRequiredDate',
        Cell: cellInfo => <div>{DateTimeFormatter.formatDate(cellInfo.cell.value)}</div>
      },
      {
        Header: 'AWO Customer',
        accessor: 'customerName',
        Cell: cellInfo => <div>{cellInfo.cell.value}</div>
      },
      {
        Header: 'Pad Name / Work Site / Rig',
        accessor: 'padName',
        Cell: cellInfo => <div style={{width:"100px" , wordWrap:"break-word"}}>{cellInfo.cell.value}</div>
      },
      {
        Header: 'Well Name',
        accessor: 'wellName',
        Cell: cellInfo => <div style={{width:"100px" , wordWrap:"break-word"}}>{cellInfo.cell.value}</div>
      },
      {
        Header: 'System',
        accessor: 'wellColorSystem',
        Cell: cellInfo => <div style={{width:"100px" , wordWrap:"break-word"}}>{cellInfo.cell.value}</div>        
      },
      {
        Header: 'Service Line',
        accessor: 'serviceLine',
        Cell: cellInfo => <div style={{width:"100px" , wordWrap:"break-word"}}>{cellInfo.cell.value}</div>
      },
      {
        Header: 'Asset Count',
        accessor: 'assetCount',
        Cell: cellInfo => <div>{cellInfo.cell.value}</div>
      },
      {
        Header: 'Configuration Count',
        accessor: 'configCount',
        Cell: cellInfo => <div>{cellInfo.cell.value}</div>
      },
      {
        Header: 'Shipped',
        accessor: 'shippedCount',
        Cell: cellInfo => <div>{cellInfo.cell.value}</div>
      },
      {
        Header : 'SAP Status',
        accessor : 'sapStatus',
        Cell: cellInfo => <div>{cellInfo.cell.value}</div>,
        isVisible : SAPService.isSAPFacility()
      }
    ],
    [showSAPStatus]
  )
  return (
    <div className='col-md-12'>
      <Grid id='results-table' allowMultiSelect={true} getCellProps={getCellProps} selectionType={props.controller.state.selectionType} selectedFlatRows={data.filter(x => x.selected === true)} columns={columns} data={data} onRowClick={handleRowClick} noDataText='No Assembly Work Orders meet the search criteria defined above' enableDefaultRowSelect={true} getRowProps={getRowProps} onPageChange={handlePageChange} />
    </div>
  );
}



const Details = (props) => {


  // const binder      = props.binder;
  const controller = props.controller;
  const selectedAWO = controller.state.selectedAWO;

  let locationElement = <Form.StaticData label='Current Location' value={selectedAWO?.customerLocation} />;
  if (LoginService.isAdministrator) {
    locationElement = selectedAWO && selectedAWO.customerLocation
      ? <Form.Link label='Current Location' value={selectedAWO.customerLocation} to={'/customerlocations/' + selectedAWO?.customerLocationId} />
      : <Form.StaticData label='Current Location' value={selectedAWO?.customerLocation} />;
  }

  return (
    <Jumbotron {...props} id='job-description' className='form-group col-md-12'>
      <div className='row' style={{ wordWrap: "break-word" }}>
        <div className='col-sm-6'><Form.StaticData label='AWO Number' value={selectedAWO?.awoNumber} /></div>
        <div className='col-sm-6'><Form.StaticData label='Functional Location' value={selectedAWO?.functionalLocation} /></div>
      </div>
      <div className='row' style={{ wordWrap: "break-word" }}>
        <div className='col-6'><Form.StaticData label='AWO Customer' value={selectedAWO?.customerName} /></div>
        <div className='col-6'><Form.StaticData label='AWO Creation Date' value={DateTimeFormatter.formatDate(selectedAWO?.awoCreationDate)} /></div>
      </div>
      <div className='row' style={{ wordWrap: "break-word" }}>
        <div className='col-sm-6'><Form.StaticData label='Pad Name / Worksite / Rig' value={selectedAWO?.padName} /></div>
        <div className='col-sm-6'><Form.StaticData label='Equipment Required Date' value={DateTimeFormatter.formatDate(selectedAWO?.equipmentRequiredDate)} /></div>
      </div>
      <div className='row' style={{ wordWrap: "break-word" }}>
        <div className='col-sm-12'><Form.StaticData label='Well Name' value={selectedAWO?.wellName} /></div>
      </div>
      <div className='row'>
        <div className='col-sm-12'><Form.StaticData label='System' value={selectedAWO?.wellColorSystem} /></div>
      </div>      
      <div className='row'>
        <div className='col-sm-12'><Form.StaticData label='Service Line' value={selectedAWO?.serviceLine} /></div>
      </div>     
      <div className='row'>
      <div className='col-sm-12'><Form.StaticData label='Owner Name' value={selectedAWO?.ownerName} /></div>
      </div>
      <div className='row'>
      <div className='col-sm-12'><Form.StaticData label='AWO Description' value={selectedAWO?.awoDescription} /></div>
      </div>
    </Jumbotron>
  );
}

const Buttons = (props) => {

  let history = useHistory();

  const handleCompleteAWO = (awo) => {
    props.controller.complete(awo);
  }

  const handleDeleteAWO = (awo) => {
    props.controller.delete(awo);
  }

  const handleExportToExcelClick = () => {
    props.controller.exportSelected()
  }
  const handlePrintAWOQrClick = (awo) => {
    return true;
  }
  const handleNotes = () => {
    const awo = controller.state.selectedAWO;
    if (!awo) {
      return;
    }
    history.push('/notes/' + AppConstants.NoteTypes.AWO + '/' + awo.id);
  }
  const handleAttachments = () => {
    const awo = controller.state.selectedAWO;
    if (!awo) {
      return;
    }
    history.push('/awoattachments/' + awo.id);
  }
  const handleEdit = () => {
    const awo = controller.state.selectedAWO;
    if (!awo) {
      return;
    }
    controller.isAWOEditable(awo.id, redirectToEditPage);
  }
  const redirectToEditPage = (awoId) => {
    history.push('/editawo/' + awoId);
  }
  const handleDuplicate = () => {
    const awo = controller.state.selectedAWO;
    if (!awo) {
      return;
    }
    history.push('/duplicateawo/' + awo.id);
  }


  const handleGenerateQRCodes = () => {
    const filterSearchResults = controller.state.searchResults.filter(x => x.selected);
    const qrSerialValues = filterSearchResults.length ? filterSearchResults : controller.state.searchResults;
    const generatedQRCodes = <div>{qrSerialValues.map(row => <AWOQRSticker qrValue={row} />)}</div>;
    var newWindow = window.open('', '_blank');
    const title = 'QR code'
    newWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="css/application.css"> </head><body>');
    ReactDOM.render(generatedQRCodes, newWindow.document.body);
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    $(newWindow).on('load', function () {
      newWindow.print();
    });
  }

  const controller = props.controller;
  const isSAPFacility = SAPService.isSAPFacility();
  const awo = controller.state.selectedAWO;
  const canCreate = true;//controller.canCreateNewAssemblyWorkOrder();

  const canDelete = true;
  const canredirect = controller.state.searchResults.filter(x => x.selected).length === 1 ? false : true;
  const canEdit = controller.state.searchActive && !canredirect && controller.state.selectedAWO.canperformactions ? false : true;
  const candelete = controller.state.searchActive && !canredirect && controller.state.selectedAWO.canperformactions && controller.state.selectedAWO.assetCount === 0 ? false : true;
  const canDuplicate = !canredirect ? false : true;
  const canGenerateQR = controller.state.searchResults.length > 0 ? false : true;
  const canComplete = !(controller.state.searchActive && !canredirect && controller.state.selectedAWO.canComplete && controller.state.selectedAWO.assetCount === controller.state.selectedAWO.shippedCount);
  return (
    <div className='mb-1'>
      <div className='col-md-12'>
        <div className='row'>

          <Button icon='fa-plus-circle' to={'/createawo/_'} disabled={!canCreate} variant='success' size='sm' className='m-1 action-button'>Create New ...</Button>
          <Dropdown.Button variant={'warning'} size={'sm'} className='action-button m-1' title='Actions' disabled={false} >
            <Dropdown.MenuItem icon='fa-solid fa-clone' disabled={canDuplicate} onClick={handleDuplicate}>Duplicate AWO...</Dropdown.MenuItem>
            <Dropdown.MenuItem icon='fa-pencil-alt' disabled={canEdit} onClick={handleEdit}>Edit AWO ...</Dropdown.MenuItem>
            <Dropdown.MenuItem icon='fa-trash' disabled={candelete} onClick={() => { props.controller.deleteAWO() }}>Delete AWO ...</Dropdown.MenuItem>
            <Dropdown.MenuItem icon='fa-regular fa-check-double' disabled={canComplete} onClick={() => { props.controller.completeAWO() }}>Complete AWO ...</Dropdown.MenuItem>
            <Dropdown.MenuItem icon='fa-sharp fa-solid fa-file-export' disabled={false} onClick={() => { handleExportToExcelClick() }}>Export ...</Dropdown.MenuItem>
            {/* <Dropdown.MenuItem icon='a-solid fa-qrcode' disabled={canGenerateQR} onClick={handleGenerateQRCodes}>Print AWO QR...</Dropdown.MenuItem> */}
          </Dropdown.Button>
          <Button icon='fa-comments' disabled={canredirect} onClick={handleNotes} variant='info' size='sm' className='action-button m-1'>Notes ...</Button>
          <Button icon='fa-plus-circle' disabled={canredirect} onClick={handleAttachments} variant='success' size='sm' className=' action-button m-1'>Attach Files ...</Button>

        </div>
      </div>
    </div>
  );
}

const AssemblyWorkOrderPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-sharp fa-solid fa-cubes' />

class AssemblyWorkOrderPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    Object.assign(this, Controller);
    Object.assign(this, Authentication);
    this.props.controller.searchAWOs();
  }

  componentDidMount = () => {
    this.initializeMixin();
    this.demandTechnician();
  }

  static defaultProps = {
    controller: new AssemblyWorkOrderController()
  }

  render() {
    var controller = this.props.controller;
    var binder = new Binder(this);

    return (
      <Page {...this.props} pageTitle='InteServ · Assembly Work Order' id='assembly-work-order-page'>
        <AssemblyWorkOrderPageHeader pageTitle={'Assembly Work Order'} />
        <Page.Content>
          <div className='row'>
            <div {...this.props} className='col-md-8'>
              <div className='row'>
                <div className='col-md-12'>
                  <SearchHeader controller={controller} binder={binder} />
                </div>
                <div className='col-md-12 table-height'>
                  <Results controller={controller} binder={binder} />
                </div>
              </div>
            </div>
            <div {...this.props} className='col-md-4'>
              <Buttons controller={controller} binder={binder} />
              <Details controller={controller} binder={binder} />
            </div>
          </div>
        </Page.Content>
      </Page>
    );
  }
}
export default AssemblyWorkOrderPage;
