import React, { useMemo, useEffect } from 'react';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
import ActionIcon from '../../components/ActionIcon';
import Form from '../../components/Form';
import Jumbotron from '../../components/Jumbotron';
import Controller from '../../mixins/Controller';
import Binder from '../../lib/Binder';
import Badge from '../../components/Badge';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Page from '../../components/Page';
import PageHeader from '../components/PageHeader';
import AssemblyController from '../controllers/AssemblyController';
import Authentication from '../services/authentication';
import LoginService from '../services/LoginService';
import SAPService from '../services/SAPService';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import AppConstants from '../utility/AppConstants';
import Utils from '../utility/Utils';
import Table from '../../components/Table';
import Grid from '../../components/Grid';
import TopPagination from '../components/TopPagination';
import Search from '../components/Search';
import Searchers from '../utility/Searchers';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import GroupBadge from '../../components/GroupBadge';
import AssemblyTab from '../components/AssemblyTab';
import messages from '../../services/Messages';

const SearchHeader = (props) => {

    const handleKeyboardSearch = (evt) => {
        if (evt.which !== 13) {
            return;
        }
        props.controller.searchAWOs();
    }

    const handleMouseSearch = () => {
        props.controller.searchAWOs();
    }

    const clearSearch = () => {
        props.controller.clearSearchText();
    }
    const selectNone = (evt) => {
        evt.preventDefault();
        props.controller.selectNone();
    }

    const selectAll = (evt) => {
        evt.preventDefault();
        props.controller.selectAll();
    }
    const binder = props.binder;
    const controller = props.controller;
    const facilityGUID = LoginService?.loginInfo?.facilityId;
    return (
        <div {...props} id='search-criteria'>
            <div className='row'>
                <div className='search-container col-md-6' onKeyDown={handleKeyboardSearch}>
                    <Form.AutoComplete bind={binder.bind('assemblyWorkOrderSearch')} label='Assembly Work Order' prependLabelIcon='fa-filter' placeholder='Select an Assembly Work Order to filter your results' search={Searchers.assemblyWorkOrderSearch(facilityGUID)} />
                </div>
            </div>
        </div>
    );
}

const ResultRow = (props) => {

    let history = useHistory();

    const selectRow = (job) => {
        props.controller.loadJobDetails(job);
    }

    const getNonSAPStatusElement = (job) => {
        let element = null;
        let statusValue = 0;

        if (job.assetCount === 0) {
            // SAP && non-SAP
            element = <Table.Data className='job-no-assets status-column' title='There are no assets on this AWO'><i className='far fa-circle' /></Table.Data>;
        }
        else if (job.isComplete) {
            statusValue += 90;
            element = <Table.Data className='job-closed status-column' title='AWO is complete – no further changes can be made'><i className='fa fa-ban' /></Table.Data>;
        }
        else if (job.canComplete) {
            statusValue += 50;
            element = <Table.Data className='job-completion-candidate status-column' title="Click 'Complete AWO' to remove this entry from the list"><i className='fa fa-check' /></Table.Data>;
        }
        else if (job.isInspecting) {
            statusValue += 40;
            element = <Table.Data className='job-active status-column' title='There are still assets that need to be shipped – unable to complete at this time'><i className='fa fa-cogs' /></Table.Data>;
        }
        else if (job.isCreated) {
            statusValue += 10;
            element = <Table.Data className='job-setting-up status-column' title='Assets can still be assigned to this AWO from the Receiving page'><i className='fa fa-download' /></Table.Data>;
        }
        else {
            statusValue += 100;
            element = <Table.Data></Table.Data>;
        }

        // set sorting value
        job.status = statusValue;
        return element;
    }


    const handleNotes = (awo) => {
        if (!awo) {
            return;
        }
        return true;
    }

    const controller = props.controller;
    const awo = props.awo;
    const showSAPStatus = props.showSAPStatus;
    let className = '';
    if (awo.sapStatus == 'QUOR')
        className = 'quorselected'
    if (controller.isSelected(awo)) {
        className = 'selected';
    }

    let requiredDateClassName = className;
    const today = DateTimeFormatter.today().format('YYYYMMDD');
    const requiredDate = DateTimeFormatter.getMoment(awo.requiredDate).format('YYYYMMDD');


    if (awo.isClosed || awo.isCompleting) {
        requiredDateClassName = className;
    }
    else if (today > requiredDate) {
        requiredDateClassName += ' late-error';
    }
    else if (today === requiredDate) {
        requiredDateClassName += ' late-warning';
    }

    const jobLink = awo.jobNumber;

    const noteCountBadge = awo.noteCount
        ? <Badge variant='warning' onClick={() => handleNotes(awo)}>{awo.noteCount}</Badge>
        : '';

    return (
        <Table.Row id={awo.id} onClick={() => { selectRow(awo); }} job={awo} isSelected={controller.isSelected(awo)}>
            <Table.Data className={className} style={{ textAlign: 'center' }}>{noteCountBadge}</Table.Data>
            <Table.Data className={className}>{jobLink}</Table.Data>
            <Table.Data className={className}>{DateTimeFormatter.formatDate(awo.jobDate)}</Table.Data>
            <Table.Data className={className}>{DateTimeFormatter.formatDate(awo.receiveDate)}</Table.Data>
            <Table.Data className={requiredDateClassName}>{DateTimeFormatter.formatDate(awo.requiredDate)}</Table.Data>
            <Table.Data className={className}>{awo.jobType}</Table.Data>
            <Table.Data className={className}>{awo.customerName}</Table.Data>
            <Table.Data className={className}>{awo.assetCount}</Table.Data>
            <Table.Data className={className}>{awo.shippedCount}</Table.Data>
            {false && <Table.Data className={className}>{awo.scrappedCount}</Table.Data>}
            {showSAPStatus ? <Table.Data className={className}>{awo.sapStatus}</Table.Data> : null}
        </Table.Row>
    );
}

const Results = (props) => {

    let history = useHistory();

    const getResultData = () => {
        // let showSAPStatus = SAPService.isSAPFacility();
        // const state = props.controller.state;
        // let searchResults = state.searchResults || [];
        // searchResults.map((awo) => {
        //     awo.status = getStatusElement(showSAPStatus, awo);
        //     return awo;
        // });
        // return searchResults;
        return props.controller.state.assets;
    }

    const getStatusElement = (showSAPStatus, awo) => {
        //return showSAPStatus ? getSAPStatusElement(job) : getNonSAPStatusElement(job);
        return true;
    }

    const getNonSAPStatusElement = (awo) => {
        let statusValue = 0;

        if (awo.assetCount === 0) {
        }
        else if (awo.isComplete) {
            statusValue += 90;
        }
        else if (awo.canComplete) {
            statusValue += 50;
        }
        else if (awo.isInspecting) {
            statusValue += 40;
        }
        else if (awo.isCreated) {
            statusValue += 10;
        }
        else {
            statusValue += 100;
        }

        // set sorting value
        //job.status = statusValue;
        return statusValue;
    }

    const handleRowClick = (assets) => {
        props.controller.setSelectedassets(assets);
    }


    const handleApplyAssemblyLevel = (evt) => {
        evt.preventDefault();
        const controller = props.controller;
        const awoId = controller.state.assemblyWorkOrderSearch.id;
        if (awoId === undefined || awoId === '') {
            return false;
        }
        const awoNumber = controller.state.awoNumber;
        const customerName = controller.state.customerName;
        const configs = controller.state.filteredconfigurations;
        const data = { awoId: awoId, awoNumber: awoNumber, customerName: customerName, configs: configs };
        messages.channel('assembly-levels').action('assign-data').post(data);
        history.push('/selectAssemblyLevels/' + awoId);
    }



    const handleReApplyAssemblyLevel = (evt) => {
        evt.preventDefault();
        const controller = props.controller;
        const awoId = controller.state.assemblyWorkOrderSearch.id;
        if (awoId === undefined || awoId === '') {
            return false;
        }
        controller.reapplyAssemblylevels();
    }

    const showSAPStatus = SAPService.isSAPFacility();
    const data = getResultData();
    const selectedgroups = props.controller.state.selectedgroups;
    const data1 = selectedgroups.length > 0 ? props.controller.state.filteredconfigurations : props.controller.state.configurations;
    const disableAssignAssembly = props.controller.state.disableAssignAssembly;
    const disableReAssignAssembly = props.controller.state.disableReAssignAssembly;
    const handlePageChange = () => {
        //TopPagination.Toggle();
    }
    const canLockAssembly = SAPService.isSAPFacility() && props.controller.canLockAssembly();
    useEffect(() => {
        handlePageChange();
    }, [data]);

    const getRowProps = (row) => {
        const awo = row.original;
        // const rowProps = { id: awo.id };
        // if (awo.sapStatus === 'QUOR') {
        //     rowProps.style = { backgroundColor: '#FFA07A' };
        // }
        if (!awo.state.isChild) {
            return { className: "font-weight-bold" }
        }
        return null;
    }
    const isSAPFacility = SAPService.isSAPFacility();
    const getCellProps = (cell) => {
        if (cell && cell.column.id === 'state.asset_Dto' || cell && cell.column.id === 'state.asset_dependent_Dto') {
            let style = {
                width: isSAPFacility ? "300px" : "500px"
            };
            return style;
        }
        return null;
    }

    //const count = props.controller.state.searchResults.length;
    const binder = props.binder;
    const columns = useMemo(
        () => [
            {
                Header: 'Equipment Number',
                accessor: 'equipmentNumber',
                Cell: cellInfo => {

                    return <>{cellInfo.cell.value}</>;
                }
            },
            {
                Header: 'Primary Serial Number',
                accessor: 'primarySerialNumber',
                Cell: cellInfo => {
                    return <>{cellInfo.cell.value}</>
                }
            },
            {
                Header: 'Object Type',
                accessor: 'objectType',
                Cell: (cellInfo) => {
                    return <>{cellInfo.cell.value}</>

                }
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: cellInfo => {
                    return <>{cellInfo.cell.value}</>
                }
            },
            {
                Header: 'Material Number',
                accessor: 'mfgPartNumber',
                Cell: cellInfo => <span>{cellInfo.cell.value}</span>
            },
            {
                Header: 'Maint Plant',
                accessor: 'plantCode',
                Cell: cellInfo => <span>{cellInfo.cell.value}</span>
            },
            {
                Header: 'Equipment Status',
                accessor: 'user_status',
                Cell: cellInfo => <span>{cellInfo.cell.value}</span>
            },
            {
                Header: 'Current Location',
                accessor: 'currentLocation',
                Cell: cellInfo => <span>{cellInfo.cell.value}</span>
            },

        ],
        [showSAPStatus]
    )
    const handleChange = (e) => {
        console.log(e)
    }
    const columns1 = useMemo(
        () => [
            {
                Header: "",
                accessor: "state.groupname",
                Cell: cellInfo => {
                    if (!cellInfo.row.original.state.isChild) {
                        if (!cellInfo.row.original.state.serviceOrderNumber) {
                            return <span className='status-column no-service-order-number' title='No SAP SO # yet'><i className='far fa-circle' /></span>;
                        }

                        else if (cellInfo.row.original.state.isWaitingForReapplyServiceLevels) {
                            return <span className='status-column asset-active' title='Waiting for Reapply Service Levels to complete'><i className='fas fa-clock asset-waiting-reapply-service-levels' /></span>;
                        }
                        else if (cellInfo.row.original.state.isWaitingForLockForInspection) {
                            return <span className='status-column asset-active' title='Waiting for Lock for Inspection request to complete'><i className='fas fa-clock asset-waiting-lock-for-inspection' /></span>;
                        }
                        else {
                            switch (cellInfo.row.original.state.sapStatus) {
                                case AppConstants.AssetSAPStatus.CRTD:
                                    return <span className='status-column asset-active' title=''><i className='far fa-clock' /></span>;

                                case AppConstants.AssetSAPStatus.REL:
                                case AppConstants.AssetSAPStatus.PCNF:
                                    return <span className='status-column asset-active' title='Assembly can be performed'><i className='fas fa-cogs' /></span>;

                                case AppConstants.AssetSAPStatus.TECO:
                                case AppConstants.AssetSAPStatus.CLSD:
                                case AppConstants.AssetSAPStatus.LKD:
                                    return <span className='status-column locked' title='No longer active on this AWO'><i className='fas fa-lock' /></span>;

                                default:
                                    return <span className='status-column no-service-order-number' title='No SAP SO # yet'><i className='fas fa-circle' /></span>;
                            }
                        }
                    }
                    else {
                        return <></>;
                    }
                },
                isVisible: isSAPFacility
            },
            {
                Header: 'Group',
                accessor: 'state.group_name',
                Cell: cellInfo => {
                    const controller = cellInfo.row.original;
                    const isshipped = controller.state.isShipped;
                    return <><span> {controller.state.group_name} </span> {isshipped ? <Badge className='text-danger' variant='info'> <strong>S</strong></Badge> : <></>}</>;
                }
            },
            {
                Header: !isSAPFacility ? 'Serial No and Description' : "Equipment Number and Description",
                accessor: 'state.asset_Dto',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return !controller.state.assemblyLevel ?
                        !controller.state.serviceOrderNumber ? <span><Form.AutoComplete style={{ "width": isSAPFacility ? "300px" : "500px" }} bind={binder.bind('asset_Dto')} search={Searchers.searchUnassingedassetsByAWO(props.controller.state.assemblyWorkOrderSearch?.id, isSAPFacility)} /></span> : <span>{controller.state.asset_Dto?.name} {isSAPFacility && !controller.state.isChild ? <> <div className='sap-token'>{'SAP Status: ' + (controller.state.sapStatus || 'not set')}</div>{controller.state.sapErrorMessage ? <div className='sap-error' style={{ fontSize: 'smaller' }}>Error: {controller.state.sapErrorCode} {controller.state.sapErrorMessage}</div> : null} </> : <></>}</span>
                        : <span>{controller.state.asset_Dto?.name} {isSAPFacility && !controller.state.isChild ? <> <div className='sap-token'>{'SAP Status: ' + (controller.state.sapStatus || 'not set')}</div>{controller.state.sapErrorMessage ? <div className='sap-error' style={{ fontSize: 'smaller' }}>Error: {controller.state.sapErrorCode} {controller.state.sapErrorMessage}</div> : null} </> : <></>}</span>
                }
            },
            {
                Header: isSAPFacility ? 'Serial Number' : 'Equipment Number',
                accessor: 'state.equipment_number',
                Cell: cellInfo => {
                    const controller = cellInfo.row.original;
                    return <span>{isSAPFacility ? controller.state.serial_primary : controller.state.equipment_number}</span>
                }
            },
            {
                Header: 'Equipment Description',
                accessor: 'state.equipment_Desc_name',
                Cell: cellInfo => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.state.equipment_Desc_name}</span>
                }
            },
            {
                Header: 'Dependent Equipment Description',
                accessor: 'state.dependent_Equipment_name',
                Cell: (cellInfo) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.state.dependent_Equipment_name}</span>
                }
            },
            {
                Header: isSAPFacility ? 'Dependent Equipment Number' : 'Dependent Equipment Serial Number',
                accessor: 'state.asset_dependent_Dto',
                Cell: (cellInfo, binder) => {
                    const controller = cellInfo.row.original;
                    return !controller.state.assemblyLevel ?
                        <span><Form.AutoComplete style={{ "width": isSAPFacility ? "300px" : "500px" }} enabled={controller.state.dependent_Equipment_name !== "n/a"} bind={binder.bind('asset_dependent_Dto')} search={Searchers.searchUnassingedassetsByAWO(props.controller.state.assemblyWorkOrderSearch?.id, isSAPFacility)} /></span>
                        : <span>{controller.state.asset_dependent_Dto?.name}</span>
                }
            },
            {
                Header: 'Position / Package',
                accessor: 'state.position_Package_name',
                Cell: (cellInfo) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.state.position_Package_name}</span>
                }
            },
            {
                Header: "Service Order Number",
                accessor: 'state.serviceOrderNumber',
                isVisible: isSAPFacility,
                Cell: (cellInfo) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.state.serviceOrderNumber}</span>
                }

            },
            {
                Header: 'Assembly Level',
                accessor: 'state.assemblyLevel',
                Cell: (cellInfo) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.state.assemblyLevel}</span>
                }
            },
            {
                Header: 'Order',
                accessor: 'state.order',
                Cell: (cellInfo) => {
                    const controller = cellInfo.row.original;
                    return <span>{controller.state.order}</span>
                }
            },

        ],
        [showSAPStatus]
    )
    const groups = [...new Set(props.controller.state.configurations.map(x => x.state.group_name))].sort();
    const handleGroupClick = (group) => {
        props.controller.setSelectedgroup(group);
    }

    const isGroupSelected = (group) => {
        let selectedgroups = props.controller.state.selectedgroups;
        return selectedgroups.includes(group);
    }
    const clearGroupSelection = () => {
        props.controller.resetGroupSelection();
    }
    const enableShipbutton = !props.controller.state.assemblyWorkOrderSearch;
    const shipassets = () => {
        history.push(`/shipsawoassets/${props.controller.state.assemblyWorkOrderSearch.id}`);
    }
    const lockAssembly = () => {
        props.controller.lockAssembly();
    }
    return (
        <>
            <div className='col-md-12'>
                <div className='d-flex justify-content-end m-2'>
                    <Buttons controller={props.controller} binder={binder} />
                </div>
            </div>
            <div className='col-md-12'>
                <div><Form.Header className='alert-warning'>Available Assets</Form.Header></div>
                <div>
                    <Grid allowMultiSelect={true} showPagination={false} id='results-table' columns={columns} data={data} onRowClick={handleRowClick} noDataText='No Assets meet the search criteria defined above' enableDefaultRowSelect={false} onPageChange={handlePageChange} />
                </div>
            </div>
            <div className='col-md-12'>
                <div><Form.Header className='alert-warning'>Assign Serial Numbers</Form.Header></div>
                <div>
                    <div className='row ml-1 mr-1'>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div id='groups' className='form-group col-12'>
                                    <div className='row'>
                                        <div className=''><Form.Label name='Groups' prependLabelIcon='fa-filter' /> </div>
                                        <div className='ml-3'><GroupBadge onClick={clearGroupSelection} variant='danger' pill={false} >Clear Group Selection</GroupBadge></div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-6'>
                                            {groups.map(x =>
                                                <GroupBadge key={x} variant={!isGroupSelected(x) ? 'secondary' : 'danger'} onClick={() => { handleGroupClick(x) }} className={'mr-1'} dataToggle="tooltip" dataPlacement="top" title={x}> {x}</GroupBadge>
                                            )}
                                        </div>
                                        <div className='col-6'>
                                            <div className='d-flex justify-content-end m-2'>
                                                <Button icon='far fa-circle' variant='primary' size='sm' className='m-1' disabled={disableAssignAssembly} onClick={handleApplyAssemblyLevel}>Apply Assembly Levels ...</Button>
                                                <Button icon='fas fa-sync' variant='secondary' size='sm' className='m-1' disabled={disableReAssignAssembly} onClick={handleReApplyAssemblyLevel}>Reapply Assembly Levels ...</Button>
                                                <Button icon='fa-lock' onClick={lockAssembly} disabled={!canLockAssembly} variant='primary' size='sm' className='btn-text-wrap mr-1 mt-1'>Lock for Assembly ...</Button>
                                                <div class="walkme-icon-image-div" style={{ backgroundImage: "no-repeat", backgroundImage: `url("https://cdn.walkme.com/player/resources/launchers/question-16-blue.png")`, height: "18px", width: "18px" }}></div>
                                                <Button onClick={shipassets} style={{ backgroundColor: "#ffc000", borderColor: "#ffc000" }} disabled={enableShipbutton} size='sm' className='btn-text-wrap ml-1 mr-1 mt-1'>Ship</Button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div style={{ width: "100%" }}>
                                    <Grid canAddNewRow={false} showPagination={false} editable={true} getCellProps={getCellProps} id='results-table' columns={columns1} data={data1} onRowClick={handleRowClick} noDataText='No System Configurations meet the search criteria defined above' getRowProps={getRowProps} onPageChange={handlePageChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

class ResultsList extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount = () => {
        const stream = this.props.controller.flash.delay(0);
        stream.onValue(this.flash);
        this.dispose = () => stream.offValue(this.flash);
    }

    componentWillUnmount = () => {
        this.dispose();
    }

    flash = (jobId) => {
        $('#results-table').find('#' + jobId + ' td')
            .velocity({ backgroundColor: '#FFFF9F' }, { duration: 1000 })
            .velocity('reverse');
    }

    getNonSAPStatusElement = (job) => {
        let statusValue = 0;

        if (job.assetCount === 0) {
        }
        else if (job.isComplete) {
            statusValue += 90;
        }
        else if (job.canComplete) {
            statusValue += 50;
        }
        else if (job.isInspecting) {
            statusValue += 40;
        }
        else if (job.isCreated) {
            statusValue += 10;
        }
        else {
            statusValue += 100;
        }

        // set sorting value
        //job.status = statusValue;
        return statusValue;
    }

    getSAPStatusElement = (job) => {
        //let statusValue = 0;
        //const warning = (job.sapErrorMessage !== '')
        //  ? <span>&nbsp;<i className='fa fa-exclamation-triangle sap-error' /></span>
        //  : null;

        //if (warning) {
        //  statusValue++;
        //}

        //if ((job.sapStatus == 'QUNR' || job.sapStatus == 'QUNC') && job.assetCount === 0) {
        //}
        //else if (job.isClosed) {
        //  // SAP && non-SAP
        //  statusValue += 90;
        //}
        //else if (job.isQuoteSent) {
        //  statusValue += 38;
        //}
        //else if (job.sapStatus == 'QUOR' && !job.isQuoteSent && !job.isInvoiced && !job.canInvoice && !job.isCompleting && !job.canComplete) {
        //  statusValue += 35;
        //}
        //else if (job.isInvoiced) {
        //  // SAP only
        //  statusValue += 80;
        //}
        //else if (job.canInvoice) {
        //  // SAP only
        //  statusValue += 70;
        //}
        //else if (job.isCompleting) {
        //  // SAP only
        //  statusValue += 60;
        //}
        //else if (job.canComplete) {
        //  // SAP && non-SAP
        //  statusValue += 50;
        //}
        //else if (job.isWaitingToRetry) {
        //  statusValue += 45;
        //}
        //else if (job.isInspecting) {
        //  // SAP && non-SAP
        //  statusValue += 40;
        //}
        //else if (job.canLockForInspection) {
        //  // SAP && non-SAP
        //  statusValue += 30;
        //}
        //else if (job.isWaiting) {
        //  // SAP && non-SAP
        //  statusValue += 20;
        //}
        //else if (job.isCreated) {
        //  statusValue += 10;
        //}

        //else {
        //  statusValue += 100;
        // }

        // set sorting value
        //job.status = statusValue;
        //return statusValue;
        return true;
    }

    // Note: also sets sorting value property 'status'
    getStatusElement = (showSAPStatus, job) => {
        return showSAPStatus ? this.getSAPStatusElement(job) : this.getNonSAPStatusElement(job);
    }

    getData = () => {
        const awo = this.props.controller.state.searchResults || [];
        return awo;
    }

    getResultData = () => {
        const showSAPStatus = SAPService.isSAPFacility();
        let results = [];
        const state = this.props.controller.state;
        let searchResults = state.searchResults || [];
        // const jobsWithStatus = searchResults.map((awo) => {
        //   awo.status = this.getStatusElement(showSAPStatus, awo);
        //   return awo;
        // });
        // searchResults = jobsWithStatus;
        const pageLimit = state.pageLimit || 50;
        const offset = state.offset || 0;
        results = searchResults.length < offset ? searchResults : searchResults ? searchResults.slice(offset, offset + pageLimit) : [];
        return results;
    }

    render = () => {
        const controller = this.props.controller;
        const showSAPStatus = SAPService.isSAPFacility();
        let awos = this.getResultData();
        const colSpanCount = 11;
        const loadingMessage = controller.state.isLoading && <Table.Row><Table.Data /><Table.Data colSpan={colSpanCount} className='loading'>Loading AWOs that match the search criteria defined above</Table.Data></Table.Row>;
        const nojobsMessage = !loadingMessage && !awos.length && <Table.Row><Table.Data /><Table.Data colSpan={colSpanCount} className='no-search-results'>No AWOs meet the search criteria defined above</Table.Data></Table.Row>;
        const hdrSAPStatus = showSAPStatus
            ? <Table.Head sortKey='sapStatus,jobNumber' getData={this.getData} sorter={this.sortData}>SAP Status</Table.Head>
            : null;

        awos = awos.map(awo => <ResultRow key={awo.id} controller={controller} awo={awo} useSAP showSAPStatus={showSAPStatus} />);

        return (
            <div id='search-results'>
                <Table id='results-table'>
                    <Table.Header>
                        <Table.Row>
                            <Table.Head sortKey='status,jobNumber' className='status-column' getData={this.getData} sorter={this.sortData} />
                            <Table.Head>Notes</Table.Head>
                            <Table.Head sortKey='jobNumber' getData={this.getData} sorter={this.sortData}>Job Number</Table.Head>
                            <Table.Head sortKey='jobDate,jobNumber' getData={this.getData} sorter={this.sortData}>Job Date</Table.Head>
                            <Table.Head sortKey='receiveDate,jobNumber' getData={this.getData} sorter={this.sortData}>Receive Date</Table.Head>
                            <Table.Head sortKey='requiredDate,jobNumber' getData={this.getData} sorter={this.sortData}>Requested Date</Table.Head>
                            <Table.Head sortKey='jobType,jobNumber' getData={this.getData} sorter={this.sortData}>Job Type</Table.Head>
                            <Table.Head sortKey='customerName,jobNumber' getData={this.getData} sorter={this.sortData}>Customer</Table.Head>
                            <Table.Head sortKey='assetCount,jobNumber' getData={this.getData} sorter={this.sortData}>Asset Count</Table.Head>
                            <Table.Head sortKey='shippedCount,jobNumber' getData={this.getData} sorter={this.sortData}>Shipped</Table.Head>
                            {hdrSAPStatus}
                        </Table.Row >
                    </Table.Header>
                    <Table.Body>
                        {awos}
                        {loadingMessage}
                        {nojobsMessage}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

const Buttons = (props) => {

    let history = useHistory();

    const controller = props.controller;
    const canCreate = true;//controller.canCreateNewAssemblyWorkOrder();
    const canEdit = true;// job && !job.isComplete;
    const canRemove = !controller.state.selectedtedassets.length > 0;
    const canComplete = true;
    const canDelete = true;
    const removeassets = () => {
        props.controller.removeUnassugnedassets();
    }
    return (
        <div className='mb-1'>
            <div className='col-md-12'>
                <div className='row'>
                    <Dropdown.Button variant={'warning'} size={'sm'} className='action-button m-1' title='Actions' disabled={false} >
                        <Dropdown.MenuItem icon='fa-trash' onClick={removeassets} disabled={canRemove}>Remove</Dropdown.MenuItem>
                        {/* <Dropdown.MenuItem icon='fa-unlock' disabled={false}>Lock Assets ...</Dropdown.MenuItem> */}
                        {/* <Dropdown.MenuItem icon='fa-truck fa-flip-horizontal' disabled={false} >Ship</Dropdown.MenuItem>
                        <Dropdown.MenuItem icon='fa-exchange-alt' disabled={canDuplicate} >Transfer</Dropdown.MenuItem> 
                        <Dropdown.MenuItem icon='fa-trash' disabled={canDuplicate}>Remove</Dropdown.MenuItem>*/}
                    </Dropdown.Button>

                </div>
            </div>
        </div>
    );
}

const AssemblyWorkOrderPageHeader = (props) => <PageHeader title={props.pageTitle} icon='fa fa-puzzle-piece' />

class AssemblyPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        Object.assign(this, Controller);
        Object.assign(this, Authentication);
        this.props.controller.load();
    }

    componentDidMount = () => {
        this.initializeMixin();
        this.demandTechnician();
    }

    static defaultProps = {
        controller: new AssemblyController()
    }

    render() {
        var controller = this.props.controller;
        var binder = new Binder(this);
        var isCompleted = controller.state.isComplete;
       

        return (
            <Page {...this.props} pageTitle='InteServ · Assembly' id='assembly-work-order-page'>
                <AssemblyWorkOrderPageHeader pageTitle={'Assembly'} />
                <Page.Content>
                    <div className='row'>
                        <div {...this.props} className='col-12'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <SearchHeader controller={controller} binder={binder} />
                                </div>
                                <div className='col-md-12 table-height'>
                                    {isCompleted !== true &&
                                        <Tabs defaultActiveKey={1} onSelect={(k) => controller.tabShow(k)}>

                                            <Tab id='yearly-calibration-tab' eventKey={1} title={"Configuration"}>
                                                <Results controller={controller} binder={binder} />
                                            </Tab>
                                            <Tab id='facility-gauge-tab' eventKey={2} title={"Assembly"}>
                                                <AssemblyTab controller={controller} binder={binder} />
                                            </Tab>
                                        </Tabs>
                                    }

                                        {isCompleted === true &&
                                        <Tabs defaultActiveKey={2} onSelect={(k) => controller.tabShow(k)}>
                                            <Tab id='assembly-completed-tab' eventKey={2} title={"Assembly"}>
                                                <AssemblyTab controller={controller} binder={binder} />
                                            </Tab>
                                        </Tabs>
                                         }
                                </div>
                            </div>
                        </div>
                        {/* <div {...this.props} className='col-md-4'>
              <Buttons controller={controller} binder={binder}/>
              <Details controller={controller} binder={binder} />
            </div> */}
                    </div>
                </Page.Content>
            </Page>
        );
    }
}
export default AssemblyPage;
