import Imm from 'immutable';
import React from 'react';
import store from 'store';
import $ from 'jquery';
import BaseController from '../../lib/BaseController';
import Dialog from '../../components/Dialog';
import notification from '../../services/Notification';
import ajax from '../../services/Ajax';
import LoginService from '../services/LoginService';
import messages from '../../services/Messages';
import co from '../../lib/Co';
import emitter from '../utility/Emitter';
import Utils from '../utility/Utils';
import Form from '../../components/Form';
import CompleteAWODialog from '../dialogs/CompleteAWODialog';
import DateTimeFormatter from '../../lib/DateTimeFormatter';
import SystemConfifRowController from './SystemConfigRowController';
import notifications from '../../services/Notification';
import lodash, { result } from 'lodash';
import { set } from 'immutable';
import ConfirmReapplyAssemblyLevelsDialog from '../dialogs/ConfirmReapplyAssemblyLevelsDialog';
import ConfirmReapplyAssemblyLevelsController from '../controllers/ConfirmReapplyAssemblyLevelsController';
import ServiceLevelHelper from '../services/ServiceLevelHelper';
import AssemblylevelHelper from '../services/AssemblylevelHelper';
import InspectionTestIcons from '../utility/InspectionTestIcons';
import AppConstants from '../utility/AppConstants';
import AssemblyOperationPassedDialog from '../dialogs/AssemblyOperationPassedDialog';
import AssemblyOperationPassedController from './AssemblyOperationPassedController';
import AssemblyOperationsNotPerformedDialog from '../dialogs/AssemblyOperationsNotPerformedDialog';
import AssemblyOperationNotPerformedController from '../controllers/AssemblyOperationNotPerformedController';
import AssemblyOperationTestNotesDialog from '../dialogs/AssemblyOperationTestNotesDialog';
import AssemblyOperationNotesController from '../controllers/AssemblyOperationNotesController';
import RecordAssemblyOperationTimeDialog from '../dialogs/RecordAssemblyOperationTimeDialog';
import RecordAssemblyOperationTimeController from '../controllers/RecordAssemblyOperationTimeController';
import AddAssemblyReworkTimeDialog from '../dialogs/AddAssemblyReworkTimeDialog';
import AddAssemblyOperationReworkTimeController from '../controllers/AddAssemblyOperationReworkTimeController';
import SAPService from '../services/SAPService';
import ComponentUpdateDialog from '../pages/ComponentUpdateDialog.js';
import ComponentUpdateController from './ComponentUpdateController.js';

const isSAPFacility = SAPService.isSAPFacility();
class AssemblyController extends BaseController {
  constructor() {
    super('assembly-work-order', {
      assemblyWorkOrderSearch: '',
      configurations: [],
      assets: [],
      selectedtedassets: [],
      selectedgroups: [],
      filteredconfigurations: [],
      selectedAssemblyGroups: [],
      selectedassetgroups: [],
      groups: [],
      disableAssignAssembly: true,
      disableReAssignAssembly: true,
      awoNumber: '',
      customerName: '',
      selectedConfigs: '',
      assemblyTests: [
        { name: 'Visual Inspection', className: 'visual', enabled: false },
        { name: 'Connection Prep', className: 'connectionprep', enabled: false },
        { name: 'Mag Particle Inspection (MPI)', className: 'mpi', enabled: false },
        { name: 'Thickness (UT)', className: 'thickness', enabled: false },
        { name: 'Gauge', className: 'gauge', enabled: false },
        { name: 'Teardown', className: 'teardown', enabled: false },
        { name: 'Rebuild', className: 'rebuild', enabled: false },
        { name: 'Reassemble', className: 'rebuild', enabled: false },
        { name: 'Grease', className: 'grease', enabled: false },
        { name: 'Pressure Test', className: 'pressuretest', enabled: false },
        { name: 'Teardown (Optional)', className: 'teardownopt', enabled: false },
        { name: 'Rebuild (Optional)', className: 'rebuildopt', enabled: false },
        { name: 'Pressure Test (Optional)', className: 'pressuretestopt', enabled: false },
        { name: 'Stamp/Band', className: 'stampband', enabled: false },
        { name: 'Paint', className: 'paint', enabled: false },
        { name: 'Final Inspection', className: 'finalinspection', enabled: false },
        { name: 'Hand Tight', className: 'visual', enabled: false },
        { name: 'Torque', className: 'visual', enabled: false },
        { name: 'EQ #/Paint', className: 'visual', enabled: false },
        { name: 'Package', className: 'visual', enabled: false },
        { name: 'Torque Check', className: 'visual', enabled: false },
        { name: 'Paint / EQ', className: 'visual', enabled: false }
      ],
      configs: [],
      extraTests: [],
      isSaveInProgress: false,
      disabledMessage: '',
      activeTestName: '',
      disableButtons: false,
      isComplete: false,
    });

    this.data = '';
    //this.load();
    messages.channel('apply-assembly-levels').action('assign-data').subscribe((data) => {
      ajax.get('lookup/awo/searchformattedawonumber', { awoId: data.awoId })
        .then(x => {
          this.state.assemblyWorkOrderSearch = x;
          this.commit();
          this.load();
          // this.load();
        })
    });
    messages.channel('config-asset-assignment').action('updated').subscribe((data) => {
      if (data.awoId === this.state.assemblyWorkOrderSearch?.id) {
        notifications.action(data.message.action).post(data.message);
      }
    });
    messages.channel('awo-lock-for-inspection').action('updated').subscribe((data) => {
      if (data) {
        if (data.awoId && data.message && data.message.action) {
          notifications.action(data.message.action).post(data.message);
          if (data.awoId === this.state.assemblyWorkOrderSearch?.id) {
            this.load();
          }
        }
        if (data.assetId && this.state.configurations.find(x => x.state.awo_SOW_GUID === data.assetId)) {
          this.load();
        }
        if (data.awoSOWId && this.state.configurations.find(x => x.state.awo_SOW_GUID === data.awoSOWId)) {
          this.load();
        }
      }
    });
    messages.channel('awo-receive-assets').action('assign-data').subscribe((data) => {
      if (data) {
        if (data.awoId === this.state.assemblyWorkOrderSearch?.id) {
          this.load();
        }
      }
    });
    messages.channel('reapply-service-levels').action('updated').subscribe((data) => {
      if (data.message && data.message.action) {
        notifications.action(data.message.action).post(data.message);
      }
      if (data.awoId === this.state.assemblyWorkOrderSearch?.id) {
        this.load();
      }
    });
  }

  tabShow = (tabId) => {
    if (tabId === '2') {
      this.state.activeTab = 2;
      this.loadAssembly();
    }
    else {
      this.state.activeTab = 1;
      this.getsystemconfigurationsandassets();
    }
    this.commit();
  }

  load = () => {
    this.state.activeTab = 1;
    this.commit();
    this.getsystemconfigurationsandassets();
    this.loadAssembly();
  }

  loadAssembly = () => {
    if (this.state.assemblyWorkOrderSearch) {
      this.state.selectedgroups = [];
      this.state.selectedAssemblyGroups = [];
      this.state.selectedassetgroups = [];
      this.state.awoSystemConfigurations = {};
      this.getsystemconfigurations();
    }
  }

  assemblyWorkOrderSearchChanged = () => {
    if (this.state.assemblyWorkOrderSearch) {
      if (this.state.activeTab == 2) {
        this.state.awoSystemConfigurations = {};
        this.getsystemconfigurations();
      }
      else {
        this.state.selectedgroups = [];
        this.getsystemconfigurationsandassets();
      }
    }
    else {
      this.state.activeTestName = '';
      this.state.configurations = [];
      this.state.filteredconfigurations = [];
      this.state.selectedgroups = [];
      this.state.selectedassetgroups = [];
      this.state.awoSystemConfigurations = {};
      this.state.assets = [];
      this.state.selectedAssemblyGroups = [];
      this.state.selectedAssemblyAssets = [];
      let tests = this.state.assemblyTests;
      tests.forEach(inspectionTest => {
        inspectionTest.enabled = false;
      });
      this.state.assemblyTests = tests;

      this.commit();
    }
  }

  getsystemconfigurationsandassets = () => {
    if (this.state.assemblyWorkOrderSearch) {
      ajax.get('lookup/awo/getawosystemconfigandassets', { awoId: this.state.assemblyWorkOrderSearch.id }).then(awoSystemConfigAssets => {
        const { awoSystemConfigurations: configurations, awoSystemConfigurationAssets: assets } = awoSystemConfigAssets;
        var config = configurations.map(x => new SystemConfifRowController(this, x));
        this.state.configurations = config;
        this.state.assets = assets;
        this.state.filteredconfigurations = config;
        this.state.awoNumber = awoSystemConfigAssets.awoNumber;
        this.state.isComplete = awoSystemConfigAssets.isComplete;
        this.state.customerName = awoSystemConfigAssets.customerName;
        this.state.awoDetails = awoSystemConfigAssets.awoDetails;
        this.resetGroupSelection();
        if (awoSystemConfigAssets.isComplete === true) {
          this.tabShow('2');
        }
        this.commit();
      });
    }
    else {
      this.state.configurations = [];
      this.state.assets = [];
      this.state.filteredconfigurations = [];
      this.commit();
    }

  }
  assginSerialnumberToConfiguration = (configId, sowId, childcontroller) => {
    ajax.post(sowId ? 'awoprogess/assignassettosystemconfig' : 'awoprogess/unassignassettosystemconfig', { System_Guid: configId, Awo_Sow_Guid: sowId, Awo_Id: this.state.assemblyWorkOrderSearch.id }).then(result => {
      if (result) {
        this.setEquipmentNumber(configId, childcontroller);
        this.state.assets = result;
        this.enableDisableAssembvlyLevelButton()
        this.commit();
      }
      else {
        notifications.action('error').post(`Unable to ${sowId ? "assign" : "unassign"} asset to System Configuration`);
        if (!sowId) {
          childcontroller.clearassetSelection();
        }
      }
    });
  }
  setSelectedassets = (assets) => {
    var assets = [...assets].map(x => ({ assetId: x.assetId, awo_sow_Id: x.awo_sow_Id }));
    this.state.selectedtedassets = assets;
    this.commit();
  }
  removeUnassugnedassets = () => {
    co(this, function* () {
      var bodyText = `${Utils.pluralize('Asset', 's', this.state.selectedtedassets.length)} will be removed from AWO and FLOC`;
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={500} height={200} header='Confirm Remove Assets'>
          {bodyText}
        </Dialog.OKCancelDialog>
      );
      if (result.OK) {
        ajax.post("awoprogess/deleteunassginedasset", { unassignedAssetsDTOs: this.state.selectedtedassets, Awo_Id: this.state.assemblyWorkOrderSearch.id }).then(r => {
          notifications.action('success').post(`${Utils.pluralize('Asset', 's', this.state.selectedtedassets.length)} removed successfully`);
          this.state.assets = r ? r : [];
          this.commit();
        }).catch(err => notifications.action('error').post(`Unable to remove ${Utils.pluralize('Asset', 's', this.state.selectedtedassets.length)}`));
      }
    });

  }

  setSelectedgroup = (group) => {
    let selectedAssets = [];
    let selectedgroups = this.state.selectedgroups;
    if (selectedgroups.includes(group)) {
      const index = selectedgroups.indexOf(group);
      selectedgroups.splice(index, 1);
    }
    else {
      selectedgroups.push(group);
    }
    let config = this.state.configurations.filter(x => selectedgroups.includes(x.state.group_name));
    this.state.selectedgroups = selectedgroups;
    this.state.filteredconfigurations = config;
    //this.setSelectedassets();
    this.enableDisableAssembvlyLevelButton();
    this.commit();
  }

  enableDisableAssembvlyLevelButton = () => {
    this.state.disableAssignAssembly = true;
    this.state.disableReAssignAssembly = true;

    var configs = this.state.filteredconfigurations.filter(x => x.state.asset_Dto === null || x.state.serial_primary === undefined);
    if (this.state.selectedgroups.length > 0 && configs.length === 0) {
      //verify if operations are started
      const isAssemblyStarted = this.state.filteredconfigurations.filter(config => config.state.isChild === false && config.state.isAssemblyStarted === true)
        .map(x => x.state.customerAssemblyLevel_GUID);
      if (isAssemblyStarted.length > 0) {
        this.state.disableAssignAssembly = true;
        this.state.disableReAssignAssembly = true;
        return;
      }

      const assemblyLevels = this.state.filteredconfigurations
        .map(x => x.state.customerAssemblyLevel_GUID);
      let uniqueAssemblyLevels = [...new Set(assemblyLevels)];
      if (uniqueAssemblyLevels.length === 1) {
        this.state.disableAssignAssembly = false;
      }
      if (uniqueAssemblyLevels.filter(x => x === Utils.emptyGuid).length === 0) {
        this.state.disableReAssignAssembly = false;
      }

    }

    //enable Re-apply assembly level button if a new config is added after the assembly level has already been assigned to group
    if (this.state.selectedgroups.length > 0 && configs.length === 0) {
      var diabled = false;
      this.state.selectedgroups.forEach(groupName => {
        if (diabled) {
          return;// breaks the loop
        }
        var groupConfigurations = this.state.filteredconfigurations.filter(x => x.state.group_name === groupName);
        if (groupConfigurations.filter(x => x.state.customerAssemblyLevel_GUID !== Utils.emptyGuid).length > 0) {
          this.state.disableReAssignAssembly = false;
        }
        else {
          this.state.disableReAssignAssembly = true;
          diabled = true;
        }
      });
    }
  }

  setAssemblygroup = (group) => {
    let selectedassemblygroups = this.state.selectedAssemblyGroups;
    if (selectedassemblygroups.includes(group)) {
      const index = selectedassemblygroups.indexOf(group);
      selectedassemblygroups.splice(index, 1);
    }
    else {
      selectedassemblygroups.push(group);
    }
    let config = this.state.configurations.filter(x => selectedassemblygroups.includes(x.state.group_name));
    this.state.selectedAssemblyGroups = selectedassemblygroups;
    this.state.filteredconfigurations = config;
    this.commit();
    return false;
  }

  setEquipmentNumber = (systemId, childcontroller) => {
    ajax.get('lookup/awo/getequipmentnumberbysystemid', { systemId: systemId, isSAP: SAPService.isSAPFacility() }).then(result => {
      childcontroller.setEquipmentNumber(result);
      childcontroller.commit();
    });
  }
  resetGroupSelection = () => {
    this.state.selectedgroups = [];
    this.enableDisableAssembvlyLevelButton();
    this.commit();
  }

  resetAssemblyGroupSelection = () => {
    this.getVisibleAssets().forEach(asset => asset.selected = false);
    this.state.selectedAssemblyGroups = [];
    this.state.selectedassetgroups = [];
    this.state.activeTestName = '';
    this.state.selectedAssemblyAssets = [];
    this.state.filteredconfigurations = [];
    this.clearActiveTests();
    this.commit();
    //this.assemblyWorkOrderSearchChanged();
  }

  getsystemconfigurations = () => {
    if (this.state.assemblyWorkOrderSearch) {
      ajax.get('lookup/awo/getawosystemconfig', { awoId: this.state.assemblyWorkOrderSearch.id }).then(awoSystemConfig => {
        const { awoSystemConfigurations: configurations } = awoSystemConfig;
        var config = configurations.map(x => new SystemConfifRowController(this, x));
        this.state.configurations = config;
        this.state.isComplete = awoSystemConfig.isComplete;
        this.initializeGroups();
        this.commit();
      }).catch(err => {
        notification.action('error').post({ msg: err.message, title: 'Error' });
      });
    }
    else {
      this.state.configurations = [];
      this.commit();
    }
  }

  selectAll = () => {
    this.state.selectedassetgroups = [];
    this.state.assemblylevelAssigned = true;
    this.state.disabledMessage = '';
    this.state.disableButtons = false;
    this.clearActiveTests();

    if (this.state.filteredconfigurations?.length > 0) {
      this.state.filteredconfigurations.forEach(asset => asset.selected = true);
      this.state.configurations.forEach(asset => asset.selected = true);
      var selectedassets = this.state.filteredconfigurations;
      this.state.selectedAssemblyAssets = selectedassets;
      const groups = selectedassets.reduce((groups, item) => {
        const group = (groups[item.state.group_name.toString()] || []);
        group.push(item);
        groups[item.state.group_name.toString()] = group;
        return groups;
      }, {});

      var selectedgroups = Object.entries(groups).map((x) => { return { groupname: x[0] } })
        .map(x => x.groupname);
      this.state.selectedassetgroups = selectedgroups;
    }
    else {
      this.getVisibleAssets().forEach(asset => asset.selected = true);
      this.state.configurations.forEach(asset => asset.selected = true);
      var selectedassets = this.state.configurations;
      this.state.selectedAssemblyAssets = selectedassets;
      const groups = selectedassets.reduce((groups, item) => {
        const group = (groups[item.state.group_name.toString()] || []);
        group.push(item);
        groups[item.state.group_name.toString()] = group;
        return groups;
      }, {});
      var selectedgroups = Object.entries(groups).map((x) => { return { groupname: x[0], itemcount: x[1].length } }).filter(x => x.itemcount === 1).map(x => x.groupname);
      this.state.selectedassetgroups = selectedgroups;

    }
    if (!this.validateAssemblyLevelsInSelectedgroups()) {
      return;
    }

    this.getOperationsData();
    this.commit();
  }

  clearActiveTests = () => {
    this.state.configs.configs = [];
    this.state.assemblyTests.forEach(inspectionTest => {
      inspectionTest.enabled = false;
    });
    this.state.extraTests = [];
  }

  selectNone = () => {
    this.getVisibleAssets().forEach(asset => asset.selected = false);
    let configassets = this.state.configurations;
    configassets.map(x => x.selected = false);
    this.clearActiveTests();
    this.state.selectedassetgroups = [];
    this.state.activeTestName = '';
    this.commit();
  }
  getSelectedGroups = () => {
    return this.state.groups.filter(group => group.selected);
  }
  getVisibleAssets = () => {
    return this.state.configurations.filter(asset => {
      const selectedGroups = this.getSelectedGroups();
      if (!selectedGroups.length) {
        return asset;
      }

      for (let i = 0; i < selectedGroups.length; ++i) {
        const group = selectedGroups[i];
        if (asset.groupNumber === group.value.groupNumber) {
          return asset;
        }
      }
    });
  }

  initializeGroups = () => {
    let groupsValue = [];

    this.state.configurations.filter(asset => {
      if (groupsValue.indexOf(asset.state.group_name) === -1) {
        groupsValue.push({ groupNumber: asset.state.groupNumber, groupName: asset.state.group_name });
      }
    });

    let groups = lodash.uniqWith(groupsValue, lodash.isEqual);

    groups.sort(function (a, b) {
      return (a.groupNumber - b.groupNumber);
    });

    this.state.groups = groups;

    this.state.groups = groups.map(group => {
      return {
        value: group,
        selected: false
      };
    });
  }

  validateAssemblyLevelsInSelectedgroups = () => {
    const assignedAssemblyLevels = [];
    this.state.selectedassetgroups.forEach(group => {
      this.state.configurations.filter(x => x.state.group_name === group && x.state.isChild === false).map(config => {
        assignedAssemblyLevels.push(config.state.assemblyLevel);
        if (config.state.assemblyLevel === '' || config.state.assemblyLevel === undefined || config.state.assemblyLevel === null) {
          this.state.disabledMessage = this.state.selectedassetgroups.length > 1 ? 'one of the selected group does not have a Assembly level' : 'selected group does not have a Assembly level';
          this.commit();
          return false;
        }
      })
    });
    //validate service order number
    if (isSAPFacility === true) {
      const assetsWithoutServiceOrder = this.state.selectedAssemblyAssets.filter(x => x.state.isChild == false && x.state.serviceOrderNumber == '');
      if (assetsWithoutServiceOrder.length > 0) {
        if (assetsWithoutServiceOrder.length === this.state.selectedAssemblyAssets.filter(x => x.state.isChild == false).length) {
          this.state.disabledMessage = 'Selected ' + Utils.pluralize('group', 's', assetsWithoutServiceOrder.length, false) + ' does not have a Service Order Number';
        }
        else {
          this.state.disabledMessage = this.state.selectedassetgroups.length > 1 ? 'one of the selected group does not have a Service Order Number' : 'selected group does not have a Service Order Number';
        }
        this.commit();
        return false;
      }
    }

    const uniqueAssemblyLevels = [...new Set(assignedAssemblyLevels)];
    if (uniqueAssemblyLevels.length > 1 && this.state.disabledMessage === '') {

      this.state.disabledMessage = 'Selected groups have different assembly levels';
      this.commit();
      return false;
    }
    if (this.state.disabledMessage !== '') {
      return false;
    }

    this.state.selectedassetgroups.forEach(group => {
      this.state.configurations.filter(x => x.state.group_name === group).map(config => {
        assignedAssemblyLevels.push(config.state.assemblyLevel);
        if (config.state.assemblyLevel === '' || config.state.assemblyLevel === undefined || config.state.assemblyLevel === null) {
          this.state.disabledMessage = this.state.selectedassetgroups.length > 1 ? 'one of the configuartion from selected groups does not have a Assembly level' : 'one of the configuartion from selected groups does not have a Assembly level';
          this.commit();
          return false;
        }
      })
    });

    return true;
  }

  toggleSelection = (assets) => {
    this.state.assemblylevelAssigned = true;
    this.state.disabledMessage = '';
    this.state.disableButtons = false;
    this.clearActiveTests();
    const groups = assets.reduce((groups, item) => {
      const group = (groups[item.state.group_name.toString()] || []);
      group.push(item);
      groups[item.state.group_name.toString()] = group;
      return groups;
    }, {});
    var selectedgroups = Object.entries(groups).map((x) => { return { groupname: x[0], itemcount: x[1].length } }).filter(x => x.itemcount === 1).map(x => x.groupname);
    let configassets = this.state.configurations;
    configassets.map(x => x.selected = false);
    var selectedassets = configassets.filter(x => selectedgroups.indexOf(x.state.group_name) > -1);
    selectedassets.forEach((x) => {
      var idx = configassets.indexOf(x);
      configassets[idx].selected = true;
    })
    this.state.selectedAssemblyAssets = selectedassets;
    this.state.selectedassetgroups = selectedgroups;
    this.state.configurations = configassets;
    this.commit();

    if (!this.validateAssemblyLevelsInSelectedgroups()) {
      return;
    }

    this.getOperationsData();
    this.commit();
  }


  getOperationsData = () => {
    // if (!this.validateAssemblyLevelsInSelectedgroups()) {
    //  return;
    //}

    // check if any of the selected config is already shipped
    const shippedAssetCount = this.state.selectedAssemblyAssets.filter(x => x.state.isShipped === true).length;
    if (shippedAssetCount > 0) {
      if (this.state.selectedAssemblyAssets.length === shippedAssetCount) {
        const verb = shippedAssetCount > 1 ? 'have' : 'has';
        this.state.disabledMessage = 'The selected ' + Utils.pluralize('group', 's', shippedAssetCount, false) + ' ' + verb + ' already been shipped ';
      } else {
        this.state.disabledMessage = 'At least one of the selected group has already been shipped ';
      }
      this.state.disableButtons = true;

    }
    const selectedassets = this.state.selectedAssemblyAssets;
    let awoSOWIds = selectedassets.filter(x => x.state.isChild === false).map(x => x.state.awo_SOW_GUID);

    if (awoSOWIds.length > 0) {
      ajax.post('lookup/awo/getAWOSOWTestDetails', awoSOWIds)
        .then(result => {
          this.state.configs = result;
          this.commit();
          this.configureTestButtons();
        }
        )
        .catch(x => console.error(x))
    } else {
      this.state.assemblyTests.forEach(inspectionTest => {
        inspectionTest.enabled = false;
      });
    }
  }

  assginDependentSerialnumberToConfiguration = (configId, sowId, childcontroller) => {
    ajax.post(sowId ? 'awoprogess/assigndependentassettosystemconfig' : 'awoprogess/unassigndependentassettosystemconfig', { System_Guid: configId, Awo_Sow_Guid: sowId, Awo_Id: this.state.assemblyWorkOrderSearch.id }).then(result => {
      if (result) {
        // this.setDependentEquipmentNumber(configId, childcontroller);
        this.state.assets = result;
        this.commit();
      }
      else {
        notifications.action('error').post(`Unable to ${sowId ? "assign" : "unassign"} asset to System Configuration`);
        if (!sowId) {
          childcontroller.clearassetSelection();
        }
      }
    });
  }

  setDependentEquipmentNumber = (systemId, childcontroller) => {
    ajax.get('lookup/awo/getdependentequipmentnumberbysystemid', { systemId: systemId }).then(result => {
      childcontroller.assignDependentEquipment(result);
    });
  }

  reapplyAssemblylevels = () => {
    let configIds = [];
    let selectedConfigIds = [];
    let allGroups = [];
    let groupsWithAssemblyStarted = [];
    const awoId = this.state.assemblyWorkOrderSearch.id;
    if (awoId === undefined || awoId === '') {
      return false;
    }

    const awoNumber = this.state.awoNumber;
    this.state.configurations.filter(config => config.state.isAssemblyStarted === true).map(x => {
      return groupsWithAssemblyStarted.push(x.state.group_name);
    });

    this.state.filteredconfigurations.map(x => {
      return selectedConfigIds.push(x.state.awo_SOW_GUID);
    });

    this.state.configurations.filter(config => config.state.isAssemblyStarted != true).map(x => {
      if (!groupsWithAssemblyStarted.includes(x.state.group_name)) {
        return configIds.push(x.state.awo_SOW_GUID);
      }
    });

    this.state.configurations.map(x => {
      if (!groupsWithAssemblyStarted.includes(x.state.group_name)) {
        return allGroups.push(x.state.group_name);
      }
    });

    const selectedGroups = this.state.selectedgroups;
    const groups = [...new Set(allGroups)];
    const allConfigs = configIds
    const selectedConfigs = selectedConfigIds;
    co(this, function* () {
      try {
        const controller = new ConfirmReapplyAssemblyLevelsController(awoId, awoNumber, groups, selectedGroups, allConfigs, selectedConfigs, this.state.customerName);
        const result = yield Dialog.showDialogWaitForResult(<ConfirmReapplyAssemblyLevelsDialog controller={controller} header='Confirm Reapply Assembly Levels' />);

        if (result.Save) {
          controller.save(() => { this.getsystemconfigurationsandassets(); this.enableDisableAssembvlyLevelButton(); this.commit(); });
        }
      }
      catch (e) {
        throw e;
      }
    });
  }

  configureTestButtons = () => {

    // disable all buttons + remove 'extra' buttons
    this.state.assemblyTests.forEach(inspectionTest => {
      inspectionTest.enabled = false;
    });
    this.state.extraTests = [];

    // find all selected service levels of the selected assets
    const assemblyLevels = AssemblylevelHelper.findSelectedIntersectingAssemblyLevels(this.state.configs);
    if (!assemblyLevels.length) {
      return;
    }

    // find all tests of the service levels
    const allTestsToPerform = AssemblylevelHelper.findDistinctTests(assemblyLevels);
    if (!allTestsToPerform.length) {
      return;
    }

    // enable all of the 'standard 9' buttons if they're selected
    const allTestsToPerformList = Imm.List(allTestsToPerform);

    this.state.assemblyTests.forEach(assemblyTest => {
      if (allTestsToPerformList.contains(assemblyTest.name)) {
        assemblyTest.enabled = true;
      }
    });

    const standardTests = Imm.List(this.state.assemblyTests.map(test => test.name));
    // add any dynamic buttons that don't match the standard 9
    allTestsToPerform.forEach(testToPerform => {
      if (!standardTests.contains(testToPerform)) {
        this.state.extraTests.push({ name: testToPerform, className: '', completed: 0, remaining: 0, enabled: true });
      }
    });
    this.commit();
  }

  getOperationOrder = (testName) => {
    let result = '';
    this.state.configs.configs.forEach(asset => {
      asset.assemblyLevels.forEach(serviceLevel => {
        serviceLevel.tests.forEach(test => {
          if (test.testName === testName) {
            if (result === '') {
              result = test.operationOrder;
            }
          }
        });
      });
    });
    return result;
  }


  getStatusAssetsIcon = (tests) => {
    if (!tests || !tests.length) {
      return null;
    }

    let statusIcon = null;
    tests.forEach(test => {
      let islatest = false;
      this.state.configs.configs.forEach(asset => {
        if (asset.awoSOWId == test.awoSOWId) {
          if (asset.lastTestResult && asset.lastTestResult.testName);
          {
            if ((asset.lastTestResult.testName == test.testName) && asset.IsUnTecoed) {
              islatest = true;
            }
          }
        }
      });
      if (islatest) {
        statusIcon = InspectionTestIcons.NO_ICON;
      }
      else if (DateTimeFormatter.formatDate(test.testDate) === '') {
        statusIcon = InspectionTestIcons.NO_ICON;
      }
      else {
        if (!test) {
          // if icon has either not been set (!statusIcon) or has been set and matches the desired value, set/keep that value
          // otherwise, set it to null
          statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.NO_ICON)))
            ? InspectionTestIcons.NO_ICON
            : null;
        }
        else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.FAILED)))
                ? InspectionTestIcons.FAILED
                : null;
              break;

            case AppConstants.eInspectionTestResult.TemporarilyFailed:
            case AppConstants.eInspectionTestResult.FailedInspection:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.TEMP_FAILURE)))
                ? InspectionTestIcons.TEMP_FAILURE
                : null;
              break;
            case AppConstants.eInspectionTestResult.Passed:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.PASSED)))
                ? InspectionTestIcons.PASSED
                : null;
              break;

            case AppConstants.eInspectionTestResult.OSRepair:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.OS_REPAIR)))
                ? InspectionTestIcons.OS_REPAIR
                : null;
              break;

            case AppConstants.eInspectionTestResult.WeldRepair:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.WELD_REPAIR)))
                ? InspectionTestIcons.WELD_REPAIR
                : null;
              break;

            case AppConstants.eInspectionTestResult.NotPerformed:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.NOT_PERFORMED)))
                ? InspectionTestIcons.NOT_PERFORMED
                : null;
              break;
            case AppConstants.eInspectionTestResult.Unteco:
              statusIcon = (!statusIcon || (statusIcon && (statusIcon === InspectionTestIcons.NO_ICON)))
                ? InspectionTestIcons.NO_ICON
                : null;
              break;
          }
        }
      }
    });
    if (statusIcon === null) {
      statusIcon = InspectionTestIcons.INDETERMINATE;
    }
    return statusIcon;
  }


  getStatusText = (tests) => {
    if (!tests || !tests.length) {
      return null;
    }

    let statusText = null;
    const statusPassText = <span className="passed test-status-text">P</span>;
    const statusFailText = <span className="failed test-status-text">F</span>;
    tests.forEach(test => {
      if (test.testName === 'Pressure Test') {
        if (DateTimeFormatter.formatDate(test.testDate) === '') {
          statusText = '';
        } else {

          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
              statusText = (!statusText || (statusText && (statusText === statusFailText)))
                ? statusFailText
                : null;
              break;

            case AppConstants.eInspectionTestResult.Passed:
              statusText = (!statusText || (statusText && (statusText === statusPassText)))
                ? statusPassText
                : null;
              break;
          }
        }
      }
    });
    if (statusText === null) {
      statusText = '';
    }
    return statusText;
  }

  getLastTestResultName = () => {
    let result = '';
    this.state.configs.configs.forEach(asset => {
      if (result === '') {
        result = (asset.lastTestResult && asset.lastTestResult.testName);
      }
      else {
        let testName = (asset.lastTestResult && asset.lastTestResult.testName);
        if (testName !== result) {
          result = '<>';
        }
      }
    });
    return result === '<>' ? '' : result;
  }


  getTestDuration = (testName) => {
    if (this.state.configs.configs.length === 0) {
      return '';
    }

    const allResults = [].concat.apply([], this.state.configs.configs.map(asset => asset.timeTrackingResults || []));
    const filteredTests = testName
      ? allResults.filter(test => test.testName === testName)
      : allResults.filter(() => true);
    const duration = filteredTests.reduce((acc, curr) => {
      return (acc + curr.calculatedDuration);
    }, 0);

    const formattedDuration = DateTimeFormatter.formatDuration(duration);
    if (!formattedDuration || (formattedDuration === '0s')) {
      return filteredTests.length ? '0m' : '';
    }

    return formattedDuration;
  }

  getTestResults = (selectedAssets, testName) => {
    if ((selectedAssets === null) || (selectedAssets === undefined) || !selectedAssets.length) {
      return [];
    }

    let testResults = [];
    selectedAssets.forEach(selectedAsset => {
      selectedAsset.testResults.forEach(testResult => {
        if (testResult.testName === testName) {
          testResults.push(testResult);
        }
      });
    });
    return testResults;
  }

  isGroupTestNotPerformed = (tests) => {
    if (!tests || !tests.length) {
      return false;
    }
    var notPerformedTestCount = tests.filter(x => x.testResult === AppConstants.eInspectionTestResult.NotPerformed && DateTimeFormatter.formatDate(x.testDate) !== '').length;
    return notPerformedTestCount === tests.length;
  }


  getCommonTestDate = (matchingTests) => {
    let result = 'sentinel';
    matchingTests.forEach(test => {
      let testDate = DateTimeFormatter.formatDate(test.stopTime || test.testDate);
      if (testDate === '') {
        if ((result === 'sentinel') || (result === '')) {
          result = '';
        }
        else {
          result = '[different dates]';
        }
      }

      if (result === 'sentinel') {
        // set the return value initially
        result = testDate;
      }
      else if (result !== testDate) {
        // if it has a different value, reset it
        // otherwise, if all tests have the same value, that's what will be returned
        result = '[different dates]';
      }
    });
    return (result === 'sentinel') ? '' : result;
  }


  getTimeTrackingResults = (selectedAssets, testName) => {
    if ((selectedAssets === null) || !selectedAssets.length) {
      return [];
    }

    let testResults = [];
    selectedAssets.forEach(selectedAsset => {
      selectedAsset.timeTrackingResults.forEach(testResult => {
        if (testResult.testName === testName) {
          testResults.push(testResult);
        }
      });
    });
    return testResults;
  }



  getCommonTechnician = (matchingTests) => {
    let result = 'sentinel';
    matchingTests.forEach(test => {
      if (result === 'sentinel') {
        // set the return value initially
        result = ((test.technician && test.technician.name) || test.testedBy);
      }
      else if (result !== ((test.technician && test.technician.name) || test.testedBy)) {
        // if it has a different value, reset it
        // otherwise, if all tests have the same value, that's what will be returned
        result = '[different techs]';
      }
    });
    result = (result === 'sentinel') ? '' : result;
    return result;
  }


  canAddReworkTime = (statusIcon, testUpdatedDate) => {
    //apply condition for AWO complete also
    if (!this.state.configs.configs) {
      return false;
    }
    const selectedAssets = this.state.configs.configs;
    const isuntecoed = selectedAssets.some(asset => asset.IsUnTecoed);
    if (isuntecoed && testUpdatedDate != '' && statusIcon !== InspectionTestIcons.NOT_PERFORMED)
      return true;
    if (statusIcon !== InspectionTestIcons.PASSED) {
      return false;
    }
    if (!selectedAssets.length) {
      return false;
    }
    const anyPassedFinalInspectionAssets = selectedAssets.some(asset => this.hasPassedFinalInspection(asset));
    return !anyPassedFinalInspectionAssets;
  }

  hasPassedFinalInspection = (asset) => {
    if (!asset || !asset.testResults) {
      return false;
    }
    const passedFinalInspection = asset.testResults.some(a => (a.testName === 'Final Inspection') && (a.testResult === AppConstants.eInspectionTestResult.Passed));
    return passedFinalInspection;
  }

  getOperationCode = (matchingTests) => {
    let result = '';
    matchingTests.forEach(test => {
      let operationCode = test.operationCode;
      if (operationCode !== '') {
        result = operationCode;
      }
    });
    return result;
  }

  passTest = (test) => {
    const awoId = this.state.assemblyWorkOrderSearch.id;
    const testName = test.name;
    const configs = this.state.configs.configs;
    const selectedCount = configs.length;
    const activeTest = this.state.activeTest;
    const selectedGroups = this.state.selectedassetgroups;
    let notesId = '';
    let notes = '';
    if (selectedCount === 0) {
      notification.action('warning').post('You must select at least one group to pass the ' + test.name + ' operation');
      return;
    }

    if (selectedCount === 1) {
      const awoSowIds = configs.map(asset => asset.awoSOWId);
      const awoIds = configs.map(asset => asset.awoId);
      const awoSowId = awoSowIds.length > 0 ? awoSowIds[0] : Utils.emptyGuid;
      const awoId = awoIds.length > 0 ? awoIds[0] : Utils.emptyGuid;
      const param = {
        awoId: awoId,
        awoSOWId: awoSowId,
        testName: testName,
      }
      ajax.post('lookup/awo/getAssemblyOperationTestNotes', param).then(result => {
        notesId = result.notesId;
        notes = result.notes;

        const message = 'Please confirm you wish to pass the ' + test.name + ' operation for the selected group' + (selectedCount === 1 ? '' : 's');
        Dialog.showDialog(<AssemblyOperationPassedDialog controller={new AssemblyOperationPassedController(this, awoId, testName, configs, selectedGroups, notesId, notes)} header='Confirm Pass Operation'>{message}</AssemblyOperationPassedDialog>);
      });
    }
    else {
      const message = 'Please confirm you wish to pass the ' + test.name + ' operation for the selected group' + (selectedCount === 1 ? '' : 's');
      Dialog.showDialog(<AssemblyOperationPassedDialog controller={new AssemblyOperationPassedController(this, awoId, testName, configs, selectedGroups, Utils.emptyGuid, '')} header='Confirm Pass Operation'>{message}</AssemblyOperationPassedDialog>);
    }
  }

  setActiveTest = (activeTestName) => {
    this.state.activeTestName = activeTestName;
    this.commit();
  }


  IsAssetTestAlreadyPassed = (tests, assets) => {
    if (!tests || !tests.length) {
      return false;
    }

    let status = 0;
    tests.forEach(test => {
      let islatest = false;
      assets.forEach(asset => {
        if (asset.assetId == test.assetId) {
          if (asset.lastTestResult && asset.lastTestResult.testName);
          {
            if ((asset.lastTestResult.testName == test.testName) && asset.IsUnTecoed) {
              islatest = true;
            }
          }
        }
      });
      if (islatest) {
        status = 0;
      }
      else if (DateTimeFormatter.formatDate(test.testDate) === '') {
        //status = 0;
      }
      else {
        if (!test || !test.isActive) {
          //status = 0;
        }
        else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
            case AppConstants.eInspectionTestResult.FailedInspection:
              //status = 0;
              break;
            case AppConstants.eInspectionTestResult.TemporarilyFailed:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.Passed:
              //if(!test.testName.includes('Final')) 
              status = status + 1;
              break;
            case AppConstants.eInspectionTestResult.OSRepair:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.WeldRepair:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.NotPerformed:
              //status = 0;
              break;
          }
        }
      }
    });
    if (status === 0)
      return false;
    else
      return true;
  }


  IsAssetTestAlreadyMarkedNotperformed = (tests, assets) => {
    if (!tests || !tests.length) {
      return false;
    }

    let status = 0;
    tests.forEach(test => {
      let islatest = false;
      assets.forEach(asset => {
        if (asset.assetId == test.assetId) {
          if (asset.lastTestResult && asset.lastTestResult.testName);
          {
            if ((asset.lastTestResult.testName == test.testName) && asset.IsUnTecoed) {
              islatest = true;
            }
          }
        }
      });
      if (islatest) {
        status = 0;
      }
      else if (DateTimeFormatter.formatDate(test.testDate) === '') {
        //status = 0;
      }
      else {
        if (!test || !test.isActive) {
          //status = 0;
        }
        else {
          switch (test.testResult) {
            case AppConstants.eInspectionTestResult.Failed:
            case AppConstants.eInspectionTestResult.FailedInspection:
              //status = 0;
              break;
            case AppConstants.eInspectionTestResult.TemporarilyFailed:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.Passed:
              //if(!test.testName.includes('Final')) 
              //status = status + 1;
              break;
            case AppConstants.eInspectionTestResult.OSRepair:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.WeldRepair:
              //status = 0;
              break;

            case AppConstants.eInspectionTestResult.NotPerformed:
              status = status + 1;
              break;
          }
        }
      }
    });
    if (status === 0)
      return false;
    else
      return true;
  }


  isPreviousTestComplete = (activeTest) => {
    let selectedConfigs = this.state.configs.configs;
    if (!activeTest || !selectedConfigs || !selectedConfigs.length) {
      return false;
    }
    if (activeTest.operationOrder === 1) {
      // 1st operation - ok to enable
      return true;
    }
    let previousTestOperationOrder = activeTest.operationOrder - 1;

    let status = 0;
    selectedConfigs.forEach(asset => {
      if (!asset.assemblyLevels.length) {
        return false;
      }
      const assemblyLevel = asset.assemblyLevels[0];
      const previousTest = assemblyLevel.tests.find(test => test.operationOrder === previousTestOperationOrder);
      if (!previousTest) {
        return false;
      }
      const testResults = asset.testResults.filter(test => test.testName === previousTest.testName);
      if (!testResults.length) {
        return false;
      }
      const testResult = testResults[0];
      switch (testResult.testResult) {
        case AppConstants.eInspectionTestResult.Passed:
          if (asset.IsUnTecoed && asset.lastTestResult && asset.lastTestResult.testName === testResult.testName) {
            status = status;
          }
          else if (testResult.testedBy !== null)
            status = status + 1;
          break;
        case AppConstants.eInspectionTestResult.NotPerformed:
          const statusIcon = this.getStatusAssetsIcon([asset.lastTestResult], [asset]);
          if (asset.IsUnTecoed && asset.lastTestResult && statusIcon === InspectionTestIcons.NO_ICON && testResult.operationOrder > asset.lastTestResult.operationOrder) {
            status = status;
          }
          else if (testResult.testedBy !== null)
            status = status + 1;
          break;
        default:
          return false;
      }
    });
    if (status == selectedConfigs.length)
      return true;
    else
      return false;
  }

  refreshConfigData = () => {
    const selectedGroups = this.state.selectedassetgroups;
    ajax.get('lookup/awo/getawosystemconfig', { awoId: this.state.assemblyWorkOrderSearch.id }).then(awoSystemConfig => {
      const { awoSystemConfigurations: configurations } = awoSystemConfig;
      var config = configurations.map(x => new SystemConfifRowController(this, x));
      this.state.configurations = config;

      if (this.state.selectedAssemblyGroups && this.state.selectedAssemblyGroups.length > 0) {
        const selectedassemblygroups = this.state.selectedAssemblyGroups;
        let config = this.state.configurations.filter(x => selectedassemblygroups.includes(x.state.group_name));
        //this.state.selectedAssemblyGroups = selectedassemblygroups;
        this.state.filteredconfigurations = config;
      } else {
        this.state.filteredconfigurations = config;
      }
      this.getOperationsData();
      this.commit();
      this.highlightSelectedRows(selectedGroups);

    });
  }

  highlightSelectedRows = (groups) => {
    var selectedgroups = groups;
    const configassets = this.state.configurations;
    configassets.map(x => x.selected = false);
    var selectedassets = configassets.filter(x => selectedgroups.indexOf(x.state.group_name) > -1);
    selectedassets.forEach((x) => {
      var idx = configassets.indexOf(x);
      configassets[idx].selected = true;
      x.selected = true;
    })
    this.state.selectedAssemblyAssets = selectedassets;
    this.state.selectedassetgroups = selectedgroups;
    this.state.configurations = configassets;
    this.commit();
  }

  notPerformedTest = (test) => {

    const selectedConfigs = this.state.configs.configs;
    let parentController = this;
    if (selectedConfigs.length === 0) {
      notification.action('warning').post('You must select at least one group to NOT PERFORM the ' + test.name + ' operations');
      return;
    }
    const awoSowIds = selectedConfigs.map(asset => asset.awoSOWId);
    const awoIds = selectedConfigs.map(asset => asset.awoId);
    const awoSowId = awoSowIds.length > 0 ? awoSowIds[0] : Utils.emptyGuid;
    const awoId = awoIds.length > 0 ? awoIds[0] : Utils.emptyGuid;
    const selectedGroups = this.state.selectedassetgroups;

    if (selectedConfigs.length === 1) {

      const testName = test.name;
      let notesId = '';
      let notes = '';
      const param = {
        awoId: awoId,
        awoSOWId: awoSowId,
        testName: testName,
      }
      ajax.post('lookup/awo/getAssemblyOperationTestNotes', param).then(result => {
        notesId = result.notesId;
        notes = result.notes;

        co(function* () {
          yield Dialog.showDialogWaitForResult(<AssemblyOperationsNotPerformedDialog controller={new AssemblyOperationNotPerformedController(parentController, awoId, awoSowId, 'notPerformedTest', 'NOT PERFORM', test.name, awoSowIds, test.name + ' test Not Performed', selectedGroups, notesId, notes)} header='Confirm Not Performed Operation' />);
        });
      });
    } else {
      co(function* () {
        yield Dialog.showDialogWaitForResult(<AssemblyOperationsNotPerformedDialog controller={new AssemblyOperationNotPerformedController(parentController, awoId, awoSowId, 'notPerformedTest', 'NOT PERFORM', test.name, awoSowIds, test.name + ' test Not Performed', selectedGroups, Utils.emptyGuid, '')} header='Confirm Not Performed Operation' />);
      });
    }
  }


  addTestNotes = (test) => {
    const selectedConfigs = this.state.configs.configs;
    const testName = test.name;

    let notes = '';
    if ((selectedConfigs === null) || !selectedConfigs.length) {
      // this shouldn't happen - button should only be enabled if at least one asset is selected
      return;
    }
    const awoSowIds = selectedConfigs.map(asset => asset.awoSOWId);
    const awoIds = selectedConfigs.map(asset => asset.awoId);
    const awoSowId = awoSowIds.length > 0 ? awoSowIds[0] : Utils.emptyGuid;
    const awoId = awoIds.length > 0 ? awoIds[0] : Utils.emptyGuid;
    const param = {
      awoId: awoId,
      awoSOWId: awoSowId,
      testName: testName,
    }
    ajax.post('lookup/awo/getAssemblyOperationTestNotes', param).then(result => {
      if (result) {
        Dialog.showDialog(<AssemblyOperationTestNotesDialog controller={new AssemblyOperationNotesController(awoId, testName, awoSowId, result.notesId, result.notes)} />);
      }
      else {
        Dialog.showDialog(<AssemblyOperationTestNotesDialog controller={new AssemblyOperationNotesController(awoId, testName, awoSowId)} />);
      }
    }).catch(err => {
      notification.action('error').post({ msg: err.message, title: 'Error' });
    });
  }


  recordOperationsTime = (test) => {

    const selectedConfigs = this.state.configs.configs;
    let parentController = this;
    if (selectedConfigs.length === 0) {
      notification.action('warning').post('You must select at least one group to record Time for the ' + test.name + ' operations');
      return;
    }
    const awoSowIds = selectedConfigs.map(asset => asset.awoSOWId);
    const awoIds = selectedConfigs.map(asset => asset.awoId);
    const awoSowId = awoSowIds.length > 0 ? awoSowIds[0] : Utils.emptyGuid;
    const awoId = awoIds.length > 0 ? awoIds[0] : Utils.emptyGuid;
    const selectedGroups = this.state.selectedassetgroups;


    co(function* () {
      yield Dialog.showDialogWaitForResult(<RecordAssemblyOperationTimeDialog controller={new RecordAssemblyOperationTimeController(parentController, awoId, awoSowIds, test.name, selectedGroups)} header='Record Time' />);
    });

  }


  addReworkTime = (test) => {

    const selectedConfigs = this.state.configs.configs;
    let parentController = this;
    if (selectedConfigs.length === 0) {
      notification.action('warning').post('You must select at least one group to record Time for the ' + test.name + ' operations');
      return;
    }
    const awoSowIds = selectedConfigs.map(asset => asset.awoSOWId);
    const awoIds = selectedConfigs.map(asset => asset.awoId);
    const awoSowId = awoSowIds.length > 0 ? awoSowIds[0] : Utils.emptyGuid;
    const awoId = awoIds.length > 0 ? awoIds[0] : Utils.emptyGuid;
    const selectedGroups = this.state.selectedassetgroups;

    co(function* () {
      yield Dialog.showDialogWaitForResult(<AddAssemblyReworkTimeDialog controller={new AddAssemblyOperationReworkTimeController(parentController, awoId, awoSowIds, test.name, selectedGroups)} header='Add Rework Time' />);
    });

  }
  lockAssembly = () => {
    const boldStyle = { fontWeight: 'bold' };
    const spanStyle = { whiteSpace: 'pre-wrap' };
    const divStyle = { marginTop: '10px' };
    const message1 = <span style={spanStyle}>Please confirm you wish to lock all eligible groups for AWO <span style={boldStyle}>{this.state.awoNumber}</span></span>;
    const message2 = <span style={spanStyle}><span style={boldStyle}>NOTE:</span> It is still possible to add additional groups to this Assembly Work Order &ndash; however, you can begin assembly on all eligible groups/assets upon Service Order Creation</span>;
    co(this, function* () {
      var result = yield Dialog.showDialogWaitForResult(
        <Dialog.OKCancelDialog width={550} height={300} header='Confirm Lock for Assembly'>
          <div>{message1}</div>
          <div style={divStyle}>{message2}</div>
        </Dialog.OKCancelDialog>
      );
      if (result.OK) {
        var parameters = {
          awoId: this.state.assemblyWorkOrderSearch.id,
          awoNumber: this.state.awoNumber
        };
        yield ajax.post('awoprogess/lockawoForInspection', parameters); // in: JobDto
      }
    });
  }
  canLockAssembly = () => {
    if (this.state.assemblyWorkOrderSearch) {
      var errorconfigs = this.state.configurations.filter(x => x.state.sapErrorMessage);
      if (errorconfigs.length > 0) {
        return false;
      }
      var eligilbegroups = [...new Set(this.state.configurations.filter(x => x.state.isChild === false && x.state.serial_GUID !== Utils.emptyGuid && x.state.customerAssemblyLevel_GUID !== Utils.emptyGuid && (x.state.serviceOrderNumber === '' || x.state.serviceOrderNumber === null)).map(x => x.state.group_name))];
      var eligibleassets = [...this.state.configurations.filter(x => eligilbegroups.includes(x.state.group_name))];

      if (this.state.awoDetails && eligibleassets.length > 0 && eligibleassets.filter(x => x.state.serial_GUID === Utils.emptyGuid)?.length === 0
        && eligibleassets.filter(x => x.state.customerAssemblyLevel_GUID === Utils.emptyGuid).length === 0 && eligibleassets.filter(x => x.state.isChild === false && (x.state.serviceOrderNumber === '' || x.state.serviceOrderNumber === null))?.length > 0
        && this.state.awoDetails?.canLockForInspection === true && AppConstants.AllowLockSAPStatus.indexOf(this.state.awoDetails.sapStatus) > -1 && eligibleassets.filter(x => x.state?.isWaiting === false).length === eligibleassets.length
      ) {
        return true
      }
      return false;
    }

  }

  getSelectedAssets = () => {
    const selectedGroup = this.state.selectedassetgroups[0];
    const selectedAssets = this.state.configurations.filter(asset => asset.state.group_name === selectedGroup && asset.state.isChild === false).map(x => {
      return x.state;
    });
    return selectedAssets || [];
  }


  fixPlant = (asset) => {
    const awoId = this.state.assemblyWorkOrderSearch.id;
    co(this, function* () {
      Dialog.showDialog(<ComponentUpdateDialog controller={new ComponentUpdateController(asset, AppConstants.NoteTypes.AWO, awoId)} header='Fix Maintenance Plant'></ComponentUpdateDialog>);
    });
  }

  isMultipleGroupsSelected = () => {
    if (this.state.selectedassetgroups && this.state.selectedassetgroups.length > 1) {
      return true;
    }
    return false;
  }

}
export default AssemblyController;
